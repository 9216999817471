.add-escalation-es {
  cursor: pointer;
  background: #e73c33;
  outline: none;
  border: 1px solid #e73c33;
  color: #ffffff;
  font-weight: bold;
  border-radius: 5px;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s;
  width: 100% !important;
  box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
  font-size: 14px !important;
}
.priority-label{
  font-size: 16px !important;
  text-transform: none !important;
  margin: 0px !important;
}
.selected-priority-label{
  color: #5195e0 !important;
}
.priority-radio{
  margin: 0px !important;
}
.add-escalation-es:hover {
  transform: scale(1.1);
  color: #ffffff;
  font-weight: 600;
}

.modalComponent {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  width: 100% !important;
  height: 100% !important;
}
  
.modalBackground {
  display: flex;
  position: fixed;
  z-index: 10;
  top: 2.5rem;
  right: 0;
  left: 0;
  bottom: 0;
  width: 80% !important;
  max-height: 80% !important;
  height: 80% !important;
  border-radius: 10px;
  background: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  margin: auto;
  box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
}
.modalBackground-nesc {
  width: 80% !important;
  height: 650px !important;
  margin: 93px 141px 196px 140px;
  padding: 0 0 34px !important;
  border-radius: 24px !important;
  background-color: #fff;
  display: flex;
  position: fixed;
  flex-direction: column;
  z-index: 10;
  top: 2.5rem;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
}
.es-header{
  padding: 12px 0px;
  background-color: #4a4a4a;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  text-transform: none !important;
  font-family: 'hurme1semibold' !important
}
.es-flex-row{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.es-flex-col{
  display: flex;
  flex-direction: column;
}
.es-details-box{
  height: 181px;
  width: 50%;
  margin: 21px 22px 24px 32px;
  padding: 22px 12px;
  border-radius: 8px;
  border: solid 1px rgba(81, 149, 224, 0.12);
  background-color: rgba(81, 149, 224, 0.12);
}
.es-font-16{
  font-size: 16px;
}
.es-font-black{
  color: #000000;
}
.es-remarks-box-header{ 
  width: 56px;
  height: 16px;
  flex-grow: 0;
  font-size: 14px;
  text-align: left;
  color: #606266;
}
.es-complaints-box{
  height: 225px;
  width: 50%;
  margin: 20px 39px 24px 22px;
  padding: 0 0 16px;
  border-radius: 9px;
  border: solid 1px rgba(74, 74, 74, 0.12);
  background-color: #fff;
}
.es-complaints-box-header{ 
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin: 0 0 16px;
  padding: 8px 8px 8px 16px;
  background-color: rgba(0, 0, 0, 0.08);
  text-align: left;
  color: #4a4a4a;
}
.es-remarks-box{
    width:50%;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    margin: 24px 22px 0px 32px;
    padding: 0;
}
.es-prev-remarks-box{
  width:50%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  margin: 24px 22px 0px 32px;
  padding: 0;
}
.es-remarks-box-text-area{
  height: 62px;
  border-radius: 8px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  flex-grow: 0;
  font-size: 14px;
  text-align: left;
  color: rgba(0, 0, 0, 0.8);
}
.es-prev-remarks-box-text-area{
  height: auto;
  border-radius: 8px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  flex-grow: 0;
  font-size: 14px;
  text-align: left;
  color: rgba(0, 0, 0, 0.8);
}
.es-priority-box{
    width:50%;
    gap: 16px;
}
.es-justify-between{
  justify-content:space-between;
}
.ml-auto{ 
  margin-left : auto
}
.es-cross{
  padding: 0px 6px;
}
.es-cancel-button{
  width: 112px;
  height: 37px;
  padding: 10px 32px;
  border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 7px;
  color : #fff;
  border : none !important;
  cursor: pointer;
}
.es-add-button{
  width: 92px;
  height: 37px;
  border-radius: 7px;
  background-color: #65c97a;
  border : none !important;
  color : #fff;
  cursor: pointer;
}
.es-priority-box-header{ 
  font-size: 14px;
  font-weight: bold;
  text-transform: none !important;
  text-align: left;
  color: #606266;
}
.priority-box-radio{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 12px;
  border-radius: 8px;
  gap: 10px;
  border: solid 1px #5195e0;
}
.prioity-box-content{
  gap: 32px;
}
.escalation-es {
  color: #212121;
  font-size: 1.25rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  text-align: left;
}
.es-lower{
  justify-content : end ;
  gap: 24px;
  padding: 0px 34px;
}
.inputBoxes-es {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 2rem;
}

.box1-es {
  width: 100%;
  height: 48px;
  border-radius: 10px;
  border: 1px solid #d0d0d0;
  background-color: hsla(0,0%,100%,.36);
}

.box1-es > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 4px 3%;
  align-items: flex-start;
}
.department-dropdown{
  position: relative;
  border: 1px solid #dddddd;
  border-radius: 10px;
  padding:15px;
  margin-top: 20px;
  width: 46%;
}
.department-options{
  position:absolute;
  border-radius: 10px;
  width: 46%;
  margin-top: 15px;
  background: #ffffff;
  padding: 15px;
  border: 1px solid #dddddd;
}
.disabled-button{ 
  background: #ddd;
  color: #000000;
}
.department-option{
  padding: 10px;
  cursor: pointer;
}
.inputTitle-es {
  font-size: 11px;
  color: #9b9b9b;
}

.mainInput-es {
  color: #000000 !important;
  cursor: auto !important;
  background: none !important;
  box-shadow: none !important;
  margin: unset !important;
  padding: unset !important;
  line-height: unset !important;
  font-size: 0.9rem !important;
  font-weight: 500;
  font-family: gilroy !important;
  padding-top: 3px !important;
}

.box3-es {
  width: 100%;
  height: 120px;
  border-radius: 10px;
  border: 1px solid #d0d0d0;
  background-color: hsla(0,0%,100%,.36);
}

.box3-es > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 4px 3%;
  align-items: flex-start;
}

.textarea-es {
  height: 90px;
  padding: unset !important;
  vertical-align: unset;
  margin: unset !important;
  box-shadow: none !important;
  background: none !important;
  margin-top: 4px !important;
  cursor: auto;
  color: #000000 !important;
  font-size: 0.8rem !important;
}

.bottom-button-es {
  position: absolute;
  bottom: 35px;
  right: 35px;
}

.whiteButton-es {
  width: 150px;
  height: 48px;
  text-align: center;
  text-decoration: none;
  box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
  border-radius: 10px;
  border: 2px solid #6a82f5;
  background-color: #ffffff;
  color: #6a82f5;
  outline: none;
  margin-right: 1rem;
  transition: transform 0.5s;
}

.whiteButton-es:hover {
  transform: scale(1.1);
  color: #ffffff;
  font-weight: 600;
  background-color: #6a82f5;
}
.es-complaints-forms{
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding: 10px;
}
.w-full{
  width: -webkit-fill-available !important;
}
.purpleButton-es {
  width: 150px;
  height: 48px;
  text-align: center;
  text-decoration: none;
  box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
  cursor: pointer;
  font-weight: 600;font-size: 15px;
  border-radius: 10px;
  border: 2px solid #6a82f5;
  background-color: #ffffff;
  color: #6a82f5;
  outline: none;
  transition: transform 0.5s;
}
.escalation-loader{
  position: absolute;
  border: 4px solid #999;
  border-top: 4px solid #000000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  left: 49%;
  top: 47%;
  transform: translate(-50%, -50%);
  animation: spin 2s linear infinite;
  z-index: 110; 
  /* background: rgba(0, 0, 0, 0.4);    */
  vertical-align: -webkit-baseline-middle;
}
.purpleButton-es:hover {
  transform: scale(1.1);
  color: #ffffff;
  font-weight: 600;
  background-color: #6a82f5;
}

.disable-input-es {
  background-color: #d3d3d3!important;
}