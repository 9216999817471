.modalComponent-mu {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}
  
.modalBackground-mu {
  position: fixed;
  z-index: 12;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 80vw;
  height: 80vh;
  background: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 10vh auto;
}

.subComponent-mu {
  padding: 1rem;
  height: -webkit-fill-available;
  overflow-x: hidden;
  overflow-y: auto;
}

.list-mu {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 2;
  background: #f6f6f6;
  text-shadow: 0 3px 1px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.9px;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
}

.table-mu {
  border-collapse: collapse;
  width: 100%;
  margin: 1rem 0 0;
}

.table-mu > tr {
  border-top: 2px solid #d8dbe0;
  border-bottom: 2px solid #d8dbe0;
}

.table-mu > tr > th {
  height: 3rem;
  /* color: #4f5d73; */
  font-size: 1rem;
  font-weight: bolder;
  text-shadow: 0 3px 1px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  background: #3E669F;
  color: #ffffff;
}

.table-mu > tr > td {
  height: 2.5rem;
  color: #4f5d73;
  font-size: 1rem;
  font-weight: 400;
}

.table-mu > tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* ***************************** Toogle Switch ****************************************** */
/* .toggle-switch {
  position: relative;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
} */
.toggle-switch-checkbox {
  display: none;
}
.toggle-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #ccc;
  border-radius: 20px;
  margin: 0;
}
.toggle-switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.toggle-switch-inner::before, .toggle-switch-inner::after {
  display: block;
  float: left;
  width: 50%;
  height: 34px;
  padding: 0;
  line-height: 34px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
}
.toggle-switch-inner:before {
  content: "Yes";
  text-transform: uppercase;
  padding-left: 10px;
  background-color: rgb(92, 184, 92);;
  color: #fff;
}
.toggle-switch-disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.toggle-switch-disabled::before {
  background-color: #ccc;
  cursor: not-allowed;
}
.toggle-switch-inner::after {
  content: "No";
  text-transform: uppercase;
  padding-right: 10px;
  background-color: #ccc;
  color: #fff;
  text-align: right;
}
.toggle-switch-switch {
  display: block;
  width: 24px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40px;
  border: 0 solid #ccc;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
  margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
  right: 0px;
}
