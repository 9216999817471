div#topbarinner{
	width: 100%;
	height: 60px;
	overflow: hidden;
	clear: both;
}
div#topbarouter{
	width: 100%;
	height: 60px;
	overflow: hidden;
	clear: both;
}
nav.topbar{
	width:100%;
	padding:0 10%;
	height:60px;
	top:0;
	z-index:850;
  text-align:center;
  clear:both;
  -webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);
  transform:translate3d(0,0,0);-webkit-transition:-webkit-transform 420ms cubic-bezier(.165,.84,.44,1);
  -moz-transition:-moz-transform 420ms cubic-bezier(.165,.84,.44,1);transition:transform 420ms cubic-bezier(.165,.84,.44,1);
  font-family: 'hurme1regular';
/*  
  moz-box-shadow:    0px 1px 2px 2px #111111;
  -webkit-box-shadow: 0px 1px 2px 2px #111111;
  box-shadow:         0px 1px 2px 2px #111111;*/
}



div.panelbuttons{
    width:auto;
    height: 100%;
	padding: 0px 40px;
	overflow: hidden;
}

div.panelbuttons div.panelbutton{
    width:90px;
    height: 60px;
	overflow: hidden;
	padding: 10px 10px;
	color:rgba(0,0,0,0.5);
	cursor: pointer;
	background: none;
}
div.panelbuttons div.panelbutton > div.inner{
    width:70px;
	height: 40px;
}
div.panelbuttons div.panelbutton:hover{
    color:rgba(0,0,0,0.95);
	background: #eeeeee;
	cursor: pointer;
}
div.panelbuttons div.panelbutton:hover > div.inner > div.imagebox > img{
    opacity: 0.95;
}
div.panelbuttons div.panelbutton.selected{
	color:rgba(0,0,0,0.95);
	background: #eeeeee;
	cursor: pointer;
}
div.panelbuttons div.panelbutton.selected > div.inner > div.imagebox > img{
    opacity: 0.95;
}
div.panelbuttons div.panelbutton.selected:hover{
    color:rgba(0,0,0,0.95);
	background: #eeeeee;
	cursor: pointer;
}
div.panelbuttons div.panelbutton.selected:hover > div.inner > div.imagebox > img{
    opacity: 0.95;
}
div.panelbuttons div.panelbutton > div.inner > div.imagebox{
    width:100%;
	height: 25px;
	padding: 2px auto;
}
div.panelbuttons div.panelbutton > div.inner > div.imagebox > img{
	opacity: 0.5;
}
div.panelbuttons div.panelbutton > div.inner > div.textbox{
    width:100%;
	height: 15px;
	line-height: 15px;
	font-size: 11px;
}







div.panelbuttons.dark-theme div.panelbutton{
    width:90px;
    height: 60px;
	overflow: hidden;
	padding: 10px 10px;
	color:rgba(255,255,255,0.5);
	cursor: pointer;
	background: none;
}
div.panelbuttons.dark-theme div.panelbutton:hover{
    color:rgba(255,255,255,0.95);
	background: #465059;
	cursor: pointer;
}
div.panelbuttons.dark-theme div.panelbutton.selected{
	color:rgba(255,255,255,0.95);
	background: #465059;
	cursor: pointer;
}
div.panelbuttons.dark-theme div.panelbutton.selected:hover{
    color:rgba(255,255,255,0.95);
	background: #465059;
	cursor: pointer;
}





div.topbarchoiceboxes{
    width:auto;
    height: 40px;
    overflow: hidden;
    border : 1px solid #e73c33;
    border-radius: 5px;
    -webkit-border-radius: 5px;
	font-size: 13px;
	margin: 9px 10px;
}
div.topbarchoiceboxes div.topbarchoiceboxesinner{
    width:auto;
    height: 40px;
    overflow: hidden;
    padding: 0px 10px;
    line-height: 40px;
    background: #ffffff;
    color: #e73c33;
    cursor: pointer;
}
div.topbarchoiceboxes div.topbarchoiceboxesinner.selected{
    background: #e73c33;
    color: #ffffff;
}
div.topbarchoiceboxes div.topbarchoiceboxesinner:hover{
    background: #e73c33;
    color: #ffffff;
}
div.topbarchoiceboxes div.topbarchoiceboxesinner.selected:hover{
    background: #e73c33;
    color: #ffffff;
}

.dark-theme div.topbarchoiceboxes{
    width:auto;
    height: 40px;
    overflow: hidden;
    border : 1px solid #797FF2;
    border-radius: 5px;
    -webkit-border-radius: 5px;
	font-size: 13px;
	margin: 9px 10px;
}
.dark-theme div.topbarchoiceboxes div.topbarchoiceboxesinner{
    width:auto;
    height: 40px;
    overflow: hidden;
    padding: 0px 10px;
    line-height: 40px;
    background: #141F26;
    color: #797FF2;
    cursor: pointer;
}
.dark-theme div.topbarchoiceboxes div.topbarchoiceboxesinner.selected{
    background: #797FF2;
    color: #141F26;
}
.dark-theme div.topbarchoiceboxes div.topbarchoiceboxesinner:hover{
    background: #797FF2;
    color: #141F26;
}
.dark-theme div.topbarchoiceboxes div.topbarchoiceboxesinner.selected:hover{
    background: #797FF2;
    color: #141F26;
}







nav.topbar.dashboard{
	padding:0 10px;
}

nav.topbar.sticky{position:absolute;}
body.scrolleddown nav.topbar.sticky{
	-webkit-transform:translate3d(0,-70px,0);-moz-transform:translate3d(0,-70px,0);-ms-transform:translate3d(0,-70px,0);transform:translate3d(0,-70px,0);
}

nav.topbar.rollover{
	-webkit-transform:translate3d(0,-80px,0);-moz-transform:translate3d(0,-80px,0);-ms-transform:translate3d(0,-80px,0);transform:translate3d(0,-80px,0);
}
body.scrolleddown nav.topbar.rollover{
	-webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);transform:translate3d(0,0,0);
}
nav.topbar.persistent, nav.topbar.rollover{box-shadow:0 1px 1px rgba(0,0,0,.1); position: fixed;}

.dark-theme nav.topbar.persistent, .dark-theme nav.topbar.rollover{box-shadow:0 1px 10px rgba(0,0,0,.9); position: fixed;}

nav.topbar.light, nav.topbar.sticky_light{background:#ffffff;}

.dark-theme nav.topbar.light, .dark-theme nav.topbar.sticky_light{background:#141F26;}

nav.topbar.light .logo:not(.center){
	background:url('../images/Logo_On-TRANSPARENT-02.png') left top no-repeat;
	background-size:auto 35px;height:40px;width:150px;float:left;margin:15px 0 0 0px;
}
nav.topbar.transparent .logo:not(.center){
	background:url('../images/Logo_On-TRANSPARENT-02.png') left top no-repeat;
	background-size:auto 35px;height:40px;width:150px;float:left;margin:15px 0 0 0px;
}

nav.topbar.light .logo.center{
	background:url('../images/Logo_On-TRANSPARENT-02.png') left top no-repeat;
	background-size:auto 35px;height:40px;width:150px;float:left;margin:15px 0 0 0px;
}

.dark-theme nav.topbar.light .logo:not(.center){
	background:url('../images/Logo_On-TRANSPARENT-02-dark.png') left top no-repeat;
	background-size:auto 35px;height:40px;width:150px;float:left;margin:15px 0 0 0px;
}
.dark-theme nav.topbar.transparent .logo:not(.center){
	background:url('../images/Logo_On-TRANSPARENT-02-dark.png') left top no-repeat;
	background-size:auto 35px;height:40px;width:150px;float:left;margin:15px 0 0 0px;
}

.dark-theme nav.topbar.light .logo.center{
	background:url('../images/Logo_On-TRANSPARENT-02-dark.png') left top no-repeat;
	background-size:auto 35px;height:40px;width:150px;float:left;margin:15px 0 0 0px;
}

nav.topbar ul{list-style-type:none;margin:0 0px 0 0;padding:0;float:right;line-height:60px; height: 60px;}
nav.topbar ul li{display:table-cell; height: 60px; vertical-align: middle;}
nav.topbar ul .mobile_btn{display:none;}
nav.topbar ul a{
	text-decoration:none;display:inline-block;
	font-size:15px;font-family: 'hurme1bold';margin-left:9px;position:relative;cursor:pointer;
	padding:8px 7px 8px;border-radius:5px;opacity:.8; line-height: 1em;
	-webkit-box-sizing: border-box !important;
	-moz-box-sizing: border-box !important;
	box-sizing: border-box !important;
}
nav.topbar.light ul a{
	color:#444444;
}
nav.topbar.transparent ul a{
	color:#ffffff;
}

.dark-theme nav.topbar.light ul a{
	color:#f2f2f2;
}
.dark-theme nav.topbar.transparent ul a{
	color:#ffffff;
}

nav.topbar ul a.has_icon span{line-height:2.25rem;}
nav.topbar ul a:hover{opacity:1;background:rgba(255,255,255,.1);box-shadow:inset 0 1px 2px rgba(255,255,255,.05); text-decoration: none;}


nav.topbar.persistent, nav.topbar.rollover .logo:not(.center){background-position:left bottom;}

nav.topbar.light ul .mobile_btn .btn_topbar{box-shadow:inset 0 0 0 2px #444444}
nav.topbar.light ul .mobile_btn .btn_topbar:hover{box-shadow:inset 0 0 0 2px #444444;background:0 0}
nav.topbar.light ul .mobile_btn .btn_topbar_filled{color:#ffffff;background:#777777;box-shadow:none}
nav.topbar.light ul .mobile_btn .btn_topbar_filled:hover{box-shadow:none;background:#777777}

nav.topbar.light ul .btn_topbar{
	display:inline-block;padding:10px 14px 9pt;line-height:1em;
	text-decoration:none;border-radius:4px;font-size:12pt;margin-left:17px;
	box-shadow:inset 0 0 0 2px #444444;}
nav.topbar.light ul .btn_topbar:hover{
	box-shadow:inset 0 0 0 2px #444444,0 1px 1px rgba(0,0,0,.1);background:0 0;
}

nav.topbar.transparent ul .btn_topbar{
	display:inline-block;padding:10px 14px 9pt;line-height:1em;
	text-decoration:none;border-radius:4px;font-size:12pt;margin-left:17px;
	opacity:1;box-shadow:inset 0 0 0 2px rgba(255,255,255,.25);}
nav.topbar.transparent ul .btn_topbar:hover{
	box-shadow:inset 0 0 0 2px rgba(255,255,255,.75);background:0 0;
}


@media only screen and (max-width:1366px){
nav.topbar{
	padding:0 5%;
}
}

@media only screen and (max-width:768px){
nav.topbar ul li{display:none}
nav.topbar ul .mobile_btn{display:table-cell;}
nav.topbar ul .mobile_btn a{margin-left:6px}
}




nav.mobile_menu{
	display:none;opacity:0;
	-webkit-transform:translate3d(0,150%,0);-moz-transform:translate3d(0,150%,0);-ms-transform:translate3d(0,150%,0);transform:translate3d(0,150%,0);
	-webkit-transition:-webkit-transform 1ms ease 250ms,opacity 250ms cubic-bezier(.165,.84,.44,1);
	-moz-transition:-moz-transform 1ms ease 250ms,opacity 250ms cubic-bezier(.165,.84,.44,1);
	transition:transform 1ms ease 250ms,opacity 250ms cubic-bezier(.165,.84,.44,1);
	position:fixed;top:0;left:0;width:100%;height:100%;
	background: radial-gradient(circle farthest-side at right bottom,#30cfd0 ,#330867);

	font-family: 'hurme1regular';

	z-index:999;
}
/*nav.mobile_menu.apps_nav::before{background:url(/d24e/img/apps/home_background@2x.png) center -42px;background-size:64pc 385px;opacity:.25;content:'';position:absolute;top:0;left:0;bottom:0;right:0}
nav.mobile_menu.apps_nav .logo:not(.center){margin-left:0;opacity:1}
nav.mobile_menu.apps_nav .close{cursor:pointer}
nav.mobile_menu.apps_nav .mobile_menu_options{top:0;left:0}
nav.mobile_menu.apps_nav ul li:not(:first-of-type):not(:last-of-type){padding-top:10%;padding-bottom:10%}
nav.mobile_menu.apps_nav ul li a{text-decoration:none;font-size:3rem}*/
nav.mobile_menu ul{
	list-style-type:none;margin:125px 0 0 45px;padding:0;
	-webkit-transform:translate(0,20px);-moz-transform:translate(0,20px);-ms-transform:translate(0,20px);transform:translate(0,20px);
	opacity:.5;
	-webkit-transition:-webkit-transform 250ms cubic-bezier(.165,.84,.44,1),opacity 230ms cubic-bezier(.165,.84,.44,1);
	-moz-transition:-moz-transform 250ms cubic-bezier(.165,.84,.44,1),opacity 230ms cubic-bezier(.165,.84,.44,1);
	transition:transform 250ms cubic-bezier(.165,.84,.44,1),opacity 230ms cubic-bezier(.165,.84,.44,1);
}
nav.mobile_menu ul a{display:block;color:#ffffff;text-decoration:none;font-size:40px;line-height:5pc}
@media only screen and (max-height:666px){nav.mobile_menu ul{margin-top:5pc}
nav.mobile_menu ul a{font-size:36px;line-height:70px}
}
@media only screen and (max-height:567px){nav.mobile_menu ul{margin-top:70px}
nav.mobile_menu ul a{font-size:30px;line-height:60px}
}
@media only screen and (max-height:435px){nav.mobile_menu ul{margin-top:70px}
nav.mobile_menu ul a{font-size:24px;line-height:50px}
}
nav.mobile_menu .sign_up{display:block;font-size:22px;font-family: 'hurme1black';line-height:70px;position:absolute;width:100%;height:70px;bottom:0;left:0;text-align:center;background:#3e1d56;color:#fff;text-decoration:none}
nav.mobile_menu .logo:not(.center){
	background:url('http://127.0.0.1:8000/media/images/logo5mono_100.png') left top no-repeat;
	background-size:auto 25px;height:30px;width:110px;float:left;margin:15px 0 0 5%;
	opacity:.5;
}
nav.mobile_menu .close{
	position:absolute;top:13px;right:5%;color:#ffffff; font-family: 'hurme1bold';
/*	-webkit-transform:rotate(-20deg);-moz-transform:rotate(-20deg);-ms-transform:rotate(-20deg);transform:rotate(-20deg);
	-webkit-transform-origin:center 40%;transform-origin:center 40%;
	-webkit-transition:-webkit-transform .2s ease-out;-moz-transition:-moz-transform .2s ease-out;transition:transform .2s ease-out;*/
}
nav.mobile_menu .close .ts_icon_times:before{font-size:30px}
body.show_mobile_nav nav.mobile_menu{
	-webkit-transition:opacity 250ms cubic-bezier(.165,.84,.44,1);-moz-transition:opacity 250ms cubic-bezier(.165,.84,.44,1);transition:opacity 250ms cubic-bezier(.165,.84,.44,1);
	-webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);transform:translate3d(0,0,0);opacity:.99;
}
body.show_mobile_nav nav.mobile_menu ul{
	-webkit-transform:translate(0,0);-moz-transform:translate(0,0);-ms-transform:translate(0,0);transform:translate(0,0);opacity:1;
}
body.show_mobile_nav nav.mobile_menu .close{-webkit-transform:rotate(0);-moz-transform:rotate(0);-ms-transform:rotate(0);transform:rotate(0)}
@media only screen and (max-width:768px){nav.mobile_menu{display:block;}
}
body.show_mobile_nav{overflow:hidden;}
.mobile_menu.scroll{overflow-y:scroll;}
.mobile_menu .mobile_menu_wrapper{
	min-height:100%;display:-webkit-flex;display:-ms-flexbox;display:flex;-ms-flex-direction:column;-webkit-flex-direction:column;flex-direction:column;-ms-flex-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;
}
.mobile_menu .mobile_menu_wrapper ul{padding:35px 0 35px 45px;margin:0;}
.mobile_menu .mobile_menu_wrapper ul a{font-size:28px;}
.mobile_menu .mobile_menu_wrapper .mobile_menu_header{display:block;}
.mobile_menu .mobile_menu_wrapper .mobile_menu_footer{background:none;-ms-flex:1 1 auto;-webkit-flex:1 1 auto;flex:1 1 auto}
.mobile_menu .mobile_menu_wrapper .mobile_menu_footer li a{
	font-size:22px;line-height:70px;width:100%;height:70px;background:#3e1d56;color:#fff;text-decoration:none;
}
.mobile_menu .mobile_menu_wrapper .mobile_menu_footer li a .signup_icon{background:#fff;color:#3e1d56;}

nav.mobile_menu .btn_topbar{
	display:inline-block;padding:10px 14px 9pt;line-height:1em;
	text-decoration:none;border-radius:4px;font-size:12pt;margin-left:17px;
	opacity:1;box-shadow:inset 0 0 0 2px rgba(255,255,255,.25); opacity: .8;}
nav.mobile_menu .btn_topbar:hover{
	box-shadow:inset 0 0 0 2px rgba(255,255,255,.75);background:0 0; opacity: 1;
}

.manage-users {
  border: 1px solid #797FF2;
  border-radius: 5px;
  font-size: 14px;
  margin: 9px 10px;
  font-weight: 600;
  letter-spacing: 1px;
  width: auto;
  height: 40px;
  overflow: hidden;
  padding: 0px 10px;
  background: #141F26;
  color: #797FF2;
  cursor: pointer;
  outline: none;
  float: right;
}

.manage-users1 {
  border: 1px solid #e73c33;
  border-radius: 5px;
  font-size: 14px;
  margin: 9px 10px;
  font-weight: 600;
  letter-spacing: 1px;
  width: auto;
  height: 40px;
  overflow: hidden;
  padding: 0px 10px;
  background: #ffffff;
  color: #e73c33;
  cursor: pointer;
  outline: none;
  float: right;
}
.dropdown-category{
	margin-top:4px;
}
.dark-theme .dropdown-category{
	color:white;
}
.dark-theme .multiselect-country{
	color:white;
}
.dark-theme .executive-dropdown{
	color:white;
}
.dropdown-category .dropdown-check-list> ul{
	height:80px !important;
	line-height:30px !important;
}
.dropdown-category .dropdown-check-list> ul li{
	height:40px !important;
}