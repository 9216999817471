.attachButton-iu {
  width: 22px;
  height: 20px;
  display: flex;
  align-items: center;
  transform: scale(1.1);
}

.modalComponent-iu {
  position: fixed;
  z-index: 10;
  top: 60.5px;
  left: 18%;
  right: 22%;
  bottom: 0;
  /* background: rgba(0, 0, 0, 0.7); */
  overflow: hidden;
  margin: auto;
  display: flex;
}

.modalBackground-iu {
  width: 99.5%;
  height: 99.5%;
  background: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  margin: auto auto 0;
}

.component-iu {
  position: relative;
  background: #e6e6e6;
  height: 100%;
}

.header-iu {
  background: rgb(57, 192, 166);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 1.5px;
}

.header-iu > span {
  font-weight: 600;
  color: #ffffff;
  font-size: 22px;
}

.header-iu > img {
  transition: transform .5s;
  cursor: pointer;
}

.header-iu > img:hover {
  transform: scale(1.2);
}

.middle-iu {
  width: 65%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  margin: 0.3125rem auto;
}

.bottomBackground-iu {
  background: #d9d9d9;
  bottom: 0;
  position: absolute;
  width: 90%;
  height: 7rem;
  padding: 0 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  overflow-y: hidden;
}

.bottomBackground-iu::-webkit-scrollbar {
  display: none;
}

.uploadImageText-iu {
  width: 4rem;
  height: 4rem;
  background: #FCF2F2;
  border: 1px dashed #fd0d00;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.imagePreview-iu {
  width: 4rem; 
  height: 4rem;
  margin-right: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 6px;
  cursor: pointer;
}

.crossImageEsc-iu {
  position: absolute;
  z-index: 3;
  top: 0.1875rem;
  right: 0.6875rem;
  width: 1rem;
  height: 1rem;
  background: #000000;
  cursor: pointer;
}

.postButton-iu {
  display: flex;
  margin: 0rem 1.5rem;
  align-items: center;
  justify-content: flex-end;
}

.postButton-iu > img {
  width: 2.75rem;
  height: 2.75rem;
  z-index: 1;
  cursor: pointer;
  margin-bottom: -1rem;
  transition: transform .5s;
}

.postButton-iu > img:hover {
  transform: scale(1.2);
}

.activeBorder-iu {
  border: 2px solid #f56e31;
}

.uploadLogo-iu {
  width: 12rem;
  height: 12rem;
}

.loader-iu {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 6px solid #ffffff;
  border-top: 6px solid rgb(57, 192, 166);
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dragDotted-iu {
  border: 3px dashed #bbb8b8;
  width: 96%;
  height: 82%;
  margin: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  font-weight: 800;
  color: lightgray;
}

/* ********************************************** */
.loader-component-iu {
  width: 59.6%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 6;
  position: fixed;
  overflow: hidden;
  display: flex;
}

.loader-iu {
  position: absolute;
  display: flex;
  z-index: 7;
  left: 45%;
  top: 30%;
}