::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}


#dashboardmaindiv{
	min-width:100%;
	max-width:100%;
	height:100%;
	margin:auto;
	clear:both;
	text-align:center;
	overflow:hidden;
	position: relative;
}

.width20pc{
	width : 20%;
}
.skel-linear-background {
    width: 8.75rem;
    height: 1rem;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 0.75rem;
    border-radius: 0.1875rem;
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 2.5rem, #ddd 5rem);
    background-size: 37.5rem;
    animation: shine-lines 1.2s infinite linear;
}
.body-line-sk-l{
    width:auto !important;
    height: 2.5rem !important;
}
@keyframes shine-lines {
	0% {
	  background-position: -6.25rem;
	}
	40%, 100% {
	  background-position: 8.75rem;
	}
  }
.leftmain.shadow-box.dark-theme{
    box-shadow: 0px 2px 10px rgba(0, 0, 0, .9);
	moz-box-shadow:    0px 2px 10px rgba(0, 0, 0, .9);
  	-webkit-box-shadow: 0px 2px 10px rgba(0, 0, 0, .9);
}
.rightmainpanel.shadow-box.dark-theme{
    box-shadow: 0px 2px 10px rgba(0, 0, 0, .9);
	moz-box-shadow:    0px 2px 10px rgba(0, 0, 0, .9);
  	-webkit-box-shadow: 0px 2px 10px rgba(0, 0, 0, .9);
}
.chat.shadow-box.dark-theme{
    box-shadow: 0px 2px 10px rgba(0, 0, 0, .6);
	moz-box-shadow:    0px 2px 5px rgba(0, 0, 0, .6);
  	-webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, .6);
}
.chatai.shadow-box.dark-theme{
    box-shadow: 0px 2px 10px rgba(0, 0, 0, .6);
	moz-box-shadow:    0px 2px 5px rgba(0, 0, 0, .6);
  	-webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, .6);
}
.chattyping.shadow-box.dark-theme{
    box-shadow: 0px 2px 10px rgba(0, 0, 0, .6);
	moz-box-shadow:    0px 2px 5px rgba(0, 0, 0, .6);
  	-webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, .6);
}
.chataitop.shadow-box.dark-theme{
    box-shadow: 0px 2px 10px rgba(0, 0, 0, .6);
	moz-box-shadow:    0px 2px 5px rgba(0, 0, 0, .6);
  	-webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, .6);
}

.chatarchivetopbar{
	width: 100%;
	height: 60px;
}
.archiveTop{
	display: flex;
	flex-direction: row;
	padding-top:6px !important;
	padding-left: 10px !important;
	gap:15px;
	width: fit-content !important;
}
.multiselect-country{
	width:100% !important;
}
.executive-dropdown{
	width:100% !important;		
}
.dark-theme.chatarchivetopbar{
	width: 100%;
	height: 60px;
	background:#2A3740;
}
.chatarchivetopbar > div.first{
	width: 20%;
	height: 100%;
}
.chatarchivetopbar > div.second{
	width: auto;
	height: 100%;
	padding: 0px 20px;
	line-height: 60px;
}
.chatarchivetopbar > div.second{
	width: auto;
	height: 100%;
	padding: 0px 20px;
	line-height: 60px;
}
.chatarchivetopbar > div.third{
	width: auto;
	height: 100%;
	padding: 15px 20px;
}



.chatarchivemainouter{
	width: 100%;
	height: calc(100% - 65px);
	margin-top: 5px;
}
.search-Loader{
		position: fixed;
		z-index: 5;
		background: rgba(0, 0, 0, 0.6);
		width: 100vw;
		height: 100vh;
		overflow: hidden;
		/* top: 150px; */
		bottom: 0;
}
.search-Loader-sb {
		position: fixed;
		z-index: 6;
		width: 40px;
		height: 40px;
		overflow: hidden;
		border-radius: 50%;
		left: 47%;
		top: 48%;
		border: 8px solid #f3f3f3;
		border-top: 8px solid #e73c33;
		animation: spin 2s linear infinite;
}
.leftmain{
	width:18%;
	height:100%;
/* //	display: inline-block; */
	float: left;
	overflow:hidden;
	overflow-y: scroll;
	background:#ffffff;
/* //	border-right:1px solid #dddddd; */
}
.dark-theme.leftmain{
	width:18%;
	height:100%;
/* //	display: inline-block; */
	float: left;
	overflow:hidden;
	overflow-y: scroll;
	background:#2A3740;
/* //	border-right:1px solid #dddddd; */
}

.middlemain{
	width: calc(60% - 10px);
	height:100%;
/* //	display: inline-block; */
	float: left;
	overflow:hidden;
	position: relative;
	margin-left: 5px;
	margin-right: 5px;
}

#dashboardchatmainright{
	width: 22%;
	height:100%;
/* //	display: inline-block; */
	float: right;
	overflow-x:hidden;
	overflow-y:auto;
	position: relative;
}


.leftmain > div.first{
	width:100%;
	padding: 10px;
	left:0;
  	top:0px;
  	height:80px;
}
.leftmain > div.second{
	width:100%;
	padding: 0px 10px;
	height: 30px;
	clear:both;
	overflow: hidden;
	left:0;
  	top:80px;
}
div.choicethreads{
	width:auto;
	height: 30px;
	overflow: hidden;
	border : 1px solid #e73c33;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	font-size: 11px;
}
div.choicethreads div.choicethreadsinner{
	width:auto;
	height: 30px;
	overflow: hidden;
	padding: 0px 10px;
	line-height: 30px;
	background: #ffffff;
	color: #e73c33;
	cursor: pointer;
}
div.choicethreads div.choicethreadsinner.selected{
	background: #e73c33;
	color: #ffffff;
}
div.choicethreads div.choicethreadsinner:hover{
	background: #e73c33;
	color: #ffffff;
}
div.choicethreads div.choicethreadsinner.selected:hover{
	background: #e73c33;
	color: #ffffff;
}





.dark-theme div.choicethreads{
	width:auto;
	height: 30px;
	overflow: hidden;
	border : 1px solid #797FF2;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	font-size: 11px;
}
.dark-theme div.choicethreads div.choicethreadsinner{
	width:auto;
	height: 30px;
	overflow: hidden;
	padding: 0px 10px;
	line-height: 30px;
	background: #141F26;
	color: #797FF2;
	cursor: pointer;
}
.dark-theme div.choicethreads div.choicethreadsinner.selected{
	background: #797FF2;
	color: #141F26;
}
.dark-theme div.choicethreads div.choicethreadsinner:hover{
	background: #797FF2;
	color: #141F26;
}
.dark-theme div.choicethreads div.choicethreadsinner.selected:hover{
	background: #797FF2;
	color: #141F26;
}




.leftmain > div.third{
	width:100%;
	padding: 0px;
	clear:both;
	overflow-y: auto;
	overflow-x: visible;
	left:0;
  	top:140px;
  	/*bottom:170px;*/
  	bottom:0px;
}

.leftmain > div.second1{
	width:100%;
	padding: 0px;
	clear:both;
	overflow-y: auto;
	overflow-x: visible;
	left:0;
  	top:80px;
  	/*bottom:170px;*/
  	bottom:0px;
}

.thread{
	width:100%;
	height:100px;
/*	border-top:1px solid #eeeeee;
	border-bottom:1px solid #eeeeee;
	border-left: 3px solid #f2f2f2;*/
	text-align: center;
	padding:0px 10px;
	width:100%;
	cursor:pointer;
	margin-top: 5px;
	margin-bottom: 5px;
	box-shadow: 0px 1px 2px rgba(50, 50, 50, .2);
	moz-box-shadow:    0px 1px 2px rgba(50, 50, 50, .2);
	-webkit-box-shadow: 0px 1px 2px rgba(50, 50, 50, .2);
	text-overflow: ellipsis;
	white-space: nowrap;
  	/* white-space: nowrap; */
}
.thread:not(.selected):hover{
	background:#fcfcfc;
	color:#565656;
	font-family: 'hurme1semibold';
	box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
	moz-box-shadow:    0px 2px 5px rgba(50, 50, 50, .3);
  	-webkit-box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
/*	border-left: 3px solid #348dd8;*/
}
.thread.selected{
	background:#d1d1d1;
	color:#E73C33;
	font-family: 'hurme1bold';
	box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
	moz-box-shadow:    0px 2px 5px rgba(50, 50, 50, .3);
  	-webkit-box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
/*//	border-left: 3px solid #348dd8;
//	border-top:1px solid #dddddd;
//	border-bottom:1px solid #dddddd;*/
}




.dark-theme .thread{
	width:100%;
	height:100px;
/*	border-top:1px solid #eeeeee;
	border-bottom:1px solid #eeeeee;
	border-left: 3px solid #f2f2f2;*/
	text-align: center;
	padding:0px 10px;
	width:100%;
	cursor:pointer;
	margin-top: 5px;
	margin-bottom: 5px;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, .4);
	moz-box-shadow:    0px 1px 2px rgba(0, 0, 0, .4);
	-webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, .4);
	text-overflow: ellipsis;
	white-space: nowrap;
  	/* white-space: nowrap; */
}
.dark-theme .thread:not(.selected):hover{
	background:#242e36;
	color:#dddddd;
	font-family: 'hurme1semibold';
	box-shadow: 0px 2px 5px rgba(50, 50, 50, .6);
	moz-box-shadow:    0px 2px 5px rgba(0, 0, 0, .6);
  	-webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, .6);
/*	border-left: 3px solid #348dd8;*/
}
.dark-theme .thread.selected{
	background:#797FF2;
	color:#fff;
	font-family: 'hurme1bold';
	box-shadow: 0px 2px 5px rgba(50, 50, 50, .6);
	moz-box-shadow:    0px 2px 5px rgba(0, 0, 0, .6);
  	-webkit-box-shadow: 0px 2px 5px #797FF2;
/*//	border-left: 3px solid #348dd8;
//	border-top:1px solid #dddddd;
//	border-bottom:1px solid #dddddd;*/
}



.thread table{
	text-align: left;
}


.thread .code{
	width:100%;
	height:30px;
	font-size: .9rem;
}
.thread .code span.new{
	width:auto;
	height:auto;
	font-size: .4rem;
	padding : 3px 7px;
}
.thread .people{
	width:100%;
	height:20px;
	font-size: .7rem;
}
.thread .executive-name{
	width:100%;
	height:20px;
	font-size: .7rem;
}
.thread .msg{
	width:100%;
	height:30px;
	font-size: .7rem;
}
.agent-comment{
	position: absolute;
	left:0;bottom:40px;right:0;
    width:100%;
    height: 60px;
}

.chatinput {
	position: absolute;
	left:0;bottom:0;right:0;
    width:100%;
    height: 60px;
}
.chatinput > div{
	position: relative;
    width:100%;
    height: 100%;
}

.chatinput .closed{
	width: 100%;
	height: 20px;
	line-height: 20px;
}
.chatinput .closed.upper{
	margin-top: 10px;
	font-size: 1rem;
}
.chatinput .closed.lower{
	margin-bottom: 10px;
	font-size: .9rem;
}

.chatinput .aibuttonbox{
	position: absolute;
	left:0;top:0;bottom:0;
    height: 100%;
    width: 60px;
    padding: 0 5px 0 10px;
}
.chatinput .inputbuttonbox{
	position: absolute;
	right:0;top:0;bottom:0;
    height: 100%;
    width: 90px;
    padding: 0 5px 0 5px;
}
.chatinput .inputbox{
	overflow: visible;
	position: absolute;
	left:60px;right:90px;top:0;bottom:0;
    height: 100%;
    padding: 0 5px 0 10px;
}
.chatinput .inputbox #sendie{
	margin: 0;
	height:40px;
/*	height: 100%;
	width:100%;
	max-width:100%;
    font-size:1rem;line-height:60px;*/
	/*border-radius:0;-webkit-appearance:none;-moz-appearance:none;appearance:none;
	outline:0;color:#565656;margin:0 0 0;
	-webkit-transition:box-shadow 70ms ease-out,border-color 70ms ease-out;
	-moz-transition:box-shadow 70ms ease-out,border-color 70ms ease-out;
	transition:box-shadow 70ms ease-out,border-color 70ms ease-out;
	-moz-osx-font-smoothing:grayscale;
	box-shadow:none;height:auto;
	-webkit-box-sizing: border-box !important;
	-moz-box-sizing: border-box !important;
	box-sizing: border-box !important;*/
}

.chat{
	width:100%;
	position:absolute;
	top:0;left:0;bottom:110px;
	clear:both;
	overflow:hidden;
	overflow-y: auto;
/* //	border-bottom: 1px solid #dddddd; */
}

.chatArchive{
	width:100%;
	/* position:absolute;
	top:0;left:0;bottom:65px; */
	height: 100%;
	clear:both;
	overflow:hidden;
	overflow-y: auto;
/* //	border-bottom: 1px solid #dddddd; */
}

.chat div.archiveDivider{
	width: 15%;
	background: #e73c33;
	height: auto;
	padding: 20px 30px;
	margin : auto;
	margin-bottom: 100px;
	border-radius: 5px;
    -webkit-border-radius: 5px;
    box-shadow: 0px 1px 3px rgba(50, 50, 50, .3);
	moz-box-shadow:    0px 1px 3px rgba(50, 50, 50, .3);
  	-webkit-box-shadow: 0px 1px 3px rgba(50, 50, 50, .3);
}

.dark-theme .chat div.archiveDivider{
	width: 15%;
	background: #141F26;
	height: auto;
	padding: 20px 30px;
	margin : auto;
	margin-bottom: 100px;
	border-radius: 5px;
    -webkit-border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, .6);
	moz-box-shadow:    0px 2px 5px rgba(0, 0, 0, .6);
  	-webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, .6);
}

.keyArchiveBox{
	width:100%;
	height: auto;
	padding: 20px 20px;
    
}

.keyArchiveBoxInner{
	width:100%;
	height: auto;
	min-height: 40px;
	/* padding: 5px 40px; */
	border-radius: 5px;
    -webkit-border-radius: 5px;
    box-shadow: 0px 1px 3px rgba(50, 50, 50, .3);
	moz-box-shadow:    0px 1px 3px rgba(50, 50, 50, .3);
  	-webkit-box-shadow: 0px 1px 3px rgba(50, 50, 50, .3);
}

.keyArchiveBoxInner > div.header{
	width:100%;
	height: auto;
	min-height: 20px;
	padding: 5px 10px;
	font-size: 14px;
	background: #ededed;
	border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -webkit-border-bottom-left-radius: 0px;
    -webkit-border-bottom-right-radius: 0px;
    box-shadow: 0px 1px 3px rgba(50, 50, 50, .3);
	moz-box-shadow:    0px 1px 1px rgba(50, 50, 50, .3);
  	-webkit-box-shadow: 0px 1px 1px rgba(50, 50, 50, .3);
}

.dark-theme .keyArchiveBoxInner > div.header{
	width:100%;
	height: auto;
	min-height: 20px;
	padding: 5px 10px;
	font-size: 14px;
	background: rgb(14, 21, 25);
	border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -webkit-border-bottom-left-radius: 0px;
    -webkit-border-bottom-right-radius: 0px;
    box-shadow: 0px 1px 3px rgba(50, 50, 50, .3);
	moz-box-shadow:    0px 2px 5px rgba(0, 0, 0, .6);
  	-webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, .6);
}

.keyArchiveBoxInner > div.chats{
	width:100%;
	height: auto;
	min-height: 20px;
	padding: 5px 20px;
}

.chattyping{
	width:100%;
	position:absolute;
	top:0;left:0;
	z-index : 2;
	padding: 10px 10px 5px;
	font-family: 'hurme1bold';
	text-transform: uppercase;
	font-size: 14px;
	margin-bottom: 10px;
/* //	border-bottom: 1px solid #dddddd; */
}

.chatai{
	width:100%;
	position:absolute;
	z-index: 3;
	top:0;left:0;bottom:65px;
	clear:both;
	overflow:hidden;
	overflow-y: auto;
	display: none;
/* //	border-bottom: 1px solid #dddddd; */
}
.chatai.showing{
	display: block;
}

.chataitop{
	width:100%;
	padding: 10px 10px 5px;
	font-family: 'hurme1bold';
	text-transform: uppercase;
	font-size: 14px;
	margin-bottom: 10px;
/* //	border-bottom: 1px solid #dddddd; */
}

.aimessage{
	width:100%;
	height: auto;
	padding: 10px 20px;
}
.aimessage > div{
	width:auto;
	max-width: 80%;
	height: auto;
	overflow: visible;
	float: left;

	display: -ms-flexbox;
    display: -webkit-flex;
	display: flex;
	/* justify-content: flex-start; */
	flex-direction: column;
	/* justify-content: right; */
    align-items: flex-start;
}
.aimessage > div .text{
	cursor: pointer;
	height:auto;
	width: fit-content;
    max-width: 100%;
	min-width: 0px;
	padding: 10px 10px;
	font-size: 14px;
	font-family: 'hurme1ob';
	border-top-left-radius: 0px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    -webkit-border-top-left-radius: 0px;
    -webkit-border-top-right-radius: 20px;
    -webkit-border-bottom-left-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    background: #f2f2f2;
    color:#444444;
    box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
	moz-box-shadow:    0px 2px 5px rgba(50, 50, 50, .3);
  	-webkit-box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
/* //    box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07); */
	white-space: pre-line;
}

.aimessage > div .text.notification{
	font-family: 'hurme1boldob';
	background: #e73c33;
    color:#ffffff;
    text-transform: uppercase;
}



.dark-theme .aimessage > div .text{
	cursor: pointer;
	height:auto;
	width: fit-content;
    max-width: 100%;
	min-width: 0px;
	padding: 10px 10px;
	font-size: 14px;
	font-family: 'hurme1ob';
	border-top-left-radius: 0px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    -webkit-border-top-left-radius: 0px;
    -webkit-border-top-right-radius: 20px;
    -webkit-border-bottom-left-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    background: #2A3740;
    color:#dddddd;
    box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
	moz-box-shadow:    0px 2px 5px rgba(50, 50, 50, .3);
  	-webkit-box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
/* //    box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07); */
	white-space: pre-line;
}

.dark-theme .aimessage > div .text.notification{
	font-family: 'hurme1boldob';
	background: #797ff2;
    color:#ffffff;
    text-transform: uppercase;
}




.message{
	width:100%;
	height: auto;
	padding: 5px 70px 5px 40px;
}
.message > .divider{
	width:100%;
	height: auto;
	display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

	align-items: center;
	
	flex-direction: row;
}
.message > .divider .dividerline{
	/* width:100%; */
	flex : 1;
	height: 1px;
	/* display: inline-block; */
}
.message > .divider .dividertext{
	width: auto;
	padding: 0 40px;
	/* width:100%; */
	/* height: 2px; */
	/* display: inline-block; */
}
.message > .self{
	width:auto;
	max-width: 98%;
	height: auto;
	overflow: visible;
	float: right;
	display: -ms-flexbox;
    display: -webkit-flex;
	display: flex;
	/* justify-content: flex-end; */
	flex-direction: column;
	/* justify-content: right; */
    align-items: flex-end;
}
.message > .others{
	width:auto;
	max-width: 80%;
	height: auto;
	overflow: visible;
	float: left;
	display: -ms-flexbox;
    display: -webkit-flex;
	display: flex;
	/* justify-content: flex-start; */
	flex-direction: column;
	/* justify-content: right; */
    align-items: flex-start;
}

.message .buffer{
	height:15px;
	width:100%;
}

.message .sender{
	height:auto;
	width:auto;
	padding-top:15px;
	display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

	align-items: center;
	
	flex-direction: row;
}
.message .self .sender{
	justify-content: flex-end;
}
.message .others .sender{
	justify-content: flex-start;
}
.message .sender > div{
	height:auto;
	width:auto;	
	/* display: inline-block; */
}
.message .sender > .sendertext{
	padding:0 20px 10px;
}
.message .sender > .sendertext > .sendername{
	font-size:14px;
	font-weight: 500;
	font-family: 'hurme1bold';
}
.message .sender > .sendertext > .sendertime{
	padding-top: 5px;
	font-size:11px;
	font-family: 'hurme1ob';
}
.message .sender > .senderimage{
	padding-bottom: 5px;
}
.message .sender > .senderimage > img{
	border-radius: 50%;
	background-position: 50%;
}


.message .self .textNotif{
	height:auto;
	width: fit-content;
    max-width: 100%;
	min-width: 0px;
	font-size: 14px;
	border-top-left-radius: 20px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    -webkit-border-top-left-radius: 20px;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-left-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    background: #f9f9f9;
    color:#444444;
    box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
	moz-box-shadow:    0px 2px 5px rgba(50, 50, 50, .3);
  	-webkit-box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
/* //    box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07); */
}
.message .self .textNotifHeader{
	height:auto;
	width: 100%;
	max-width: auto;
	min-width: 0px;
	background: #e73c33;
    color:#ffffff;
    padding: 5px 10px;
    font-size: 12px;
    text-transform: uppercase;
    font-family: 'hurme1bold';
}


.dark-theme .message .self .textNotif{
	height:auto;
	width: fit-content;
    max-width: 100%;
	min-width: 0px;
	font-size: 14px;
	border-top-left-radius: 20px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    -webkit-border-top-left-radius: 20px;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-left-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    background: #2A3740;
    color:#eeeeee;
    box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
	moz-box-shadow:    0px 2px 5px rgba(50, 50, 50, .3);
  	-webkit-box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
/* //    box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07); */
}
.dark-theme .message .self .textNotifHeader{
	height:auto;
	width: 100%;
	max-width: auto;
	min-width: 0px;
	background: #797ff2;
    color:#ffffff;
    padding: 5px 10px;
    font-size: 12px;
    text-transform: uppercase;
    font-family: 'hurme1bold';
}


.message .self .textNotifMessage{
	height:auto;
	width: auto;
	max-width: auto;
	min-width: 0px;
    padding: 10px 10px;
    white-space: pre-line;
}



.message .self .textAction{
	height:auto;
	width: fit-content;
    max-width: 100%;
	min-width: 0px;
	font-size: 14px;
	border-top-left-radius: 20px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    -webkit-border-top-left-radius: 20px;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-left-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    background: #f9f9f9;
    color:#444444;
    box-shadow: 0px 1px 2px rgba(50, 50, 50, .3);
	moz-box-shadow:    0px 1px 2px rgba(50, 50, 50, .3);
	  -webkit-box-shadow: 0px 1px 2px rgba(50, 50, 50, .3);
/* //    box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07); */
}
.message .self .textActionHeader{
	height:auto;
	width: 100%;
	max-width: 100;
	min-width: 0px;
	flex: 0 0 100%;
	/* background: #e8e8e8; */
    color:#111111;
    padding: 10px 10px;
    font-size: 13px;
    /* text-transform: uppercase; */
    font-family: 'hurme1regular';
}

.dark-theme .message .self .textAction{
	height:auto;
	width: fit-content;
    max-width: 100%;
	min-width: 0px;
	font-size: 14px;
	border-top-left-radius: 20px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    -webkit-border-top-left-radius: 20px;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-left-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    background: #2A3740;
    color:#eeeeee;
    box-shadow: 0px 1px 2px rgba(50, 50, 50, .3);
	moz-box-shadow:    0px 1px 2px rgba(50, 50, 50, .3);
	  -webkit-box-shadow: 0px 1px 2px rgba(50, 50, 50, .3);
/* //    box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07); */
}
.dark-theme .message .self .textActionHeader{
	height:auto;
	width: 100%;
	max-width: 100%;
	min-width: 0px;
	flex: 0 0 100%;
	/* background: #e8e8e8; */
	/* color:#111111; */
	/* background: #797ff2; */
    color:#ffffff;
    padding: 10px 10px;
    font-size: 13px;
    /* text-transform: uppercase; */
    font-family: 'hurme1regular';
}

.message .self .textActionMain{
	height:auto;
	width: auto;
	max-width: 100%;
	min-width: 0px;
	padding: 0px 0px;
	white-space: nowrap;
    /* white-space: pre-line; */
}


.message .self .textActionMainChoiceTextTab{
	height:auto;
	width: auto;
	max-width: 100%;
	min-width: 0px;
	padding: 10px 10px;
	border-top: 1px solid #e8e8e8;
	cursor: pointer;
	white-space: normal;
    /* white-space: pre-line; */
}
.dark-theme .message .self .textActionMainChoiceTextTab{
	height:auto;
	width: auto;
	max-width: 100%;
	min-width: 0px;
	padding: 10px 10px;
	border-top: 1px solid #aaaaaa;
	cursor: pointer;
    /* white-space: pre-line; */
}
.message .self .textActionMainChoiceTextTab .actionTabChoiceTextTitle{
	height:auto;
	width: auto;
	max-width: 100%;
	min-width: 0px;
	font-size: 14px;
	font-family: 'hurme1semibold';
	text-overflow: ellipsis;
    /* white-space: pre-line; */
}
.message .self .textActionMainChoiceTextTab .actionTabChoiceTextDate{
	height:auto;
	width: auto;
	max-width: 100%;
	min-width: 0px;
    /* white-space: pre-line; */
}
.message .self .textActionMainChoiceTextTab .actionTabChoiceTextStatus{
	height:auto;
	width: auto;
	max-width: 100%;
	min-width: 0px;
	font-size: 10px;
    /* white-space: pre-line; */
}




.message .self .textActionMainOrderTab{
	height:auto;
	width: auto;
	max-width: auto;
	min-width: 0px;
	padding: 10px 10px;
	border-top: 1px solid #e8e8e8;
	cursor: pointer;
    /* white-space: pre-line; */
}
.dark-theme .message .self .textActionMainOrderTab{
	height:auto;
	width: auto;
	max-width: auto;
	min-width: 0px;
	padding: 10px 10px;
	border-top: 1px solid #aaaaaa;
	cursor: pointer;
    /* white-space: pre-line; */
}
.message .self .textActionMainOrderTab .actionTabOrderTitle{
	height:auto;
	width: 100%;
	max-width: auto;
	min-width: 0px;
	font-size: 14px;
	text-overflow: ellipsis;
    /* white-space: pre-line; */
}
.message .self .textActionMainOrderTab .actionTabOrderDate{
	height:auto;
	width: auto;
	max-width: auto;
	min-width: 0px;
	margin-top: 5px;
    /* white-space: pre-line; */
}
.message .self .textActionMainOrderTab .actionTabOrderStatus{
	height:auto;
	width: auto;
	max-width: auto;
	min-width: 0px;
	font-size: 10px;
    /* white-space: pre-line; */
}




.questionAnswerContainer {
	/* margin-top: 12px; */
	width: 100%;
	max-width: 100%;
	/* border-radius: 5px; */
	/* border: solid 1px rgba(155, 155, 155, 0.5); */
	/* box-shadow: 0 1px 0 0 #d5dadc, 0 -1px 0 0 #d5dadc; */
  }

  .questionContainer {
	padding: 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: -webkit-fill-available;
	cursor: pointer;
	text-align: right;
  }

  .questionText {
	font-size: 13px;
	font-family: 'hurme1bold';
	text-align: right;
	flex: 1;
	margin-right: 10px;
  }

  .archiveMessage .questionText {
	font-size: 12px;
  }

  .questionControl {
	width: 8px;
	height: 8px;
	object-fit: contain;
	transition: transform 0.5s;
  }

  .answer {
	/* background-color: #ffffff; */
	font-size: 13px;
	line-height: 1.4;
	letter-spacing: normal;
	text-align: right;
	transition: transform 0.5s;
	transform: scaleY(0);
	transform-origin: top;
	height: 0;
	padding: 0;
	width: -webkit-fill-available;
	overflow: auto;
	font-family: 'hurme1regular';
	/* color: #4a4a4a; */
	opacity: 0.8;
  }
  .archiveMessage .answer{
	  font-size: 12px;
  }

  .questionContainer[aria-expanded="true"] + .answer {
	transform: scaleY(1);
	/* padding: 12px 19px 18px 18px; */
	padding: 10px;
	height: 100%;
  }

  .questionContainer[aria-expanded="true"] > .questionControl {
	transform: rotate(180deg);
  }

  @keyframes spin {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }
  .faq-scroll-track{
	  width: 100%;
	  height: 100%;
	  margin-top: 12px;
	  border: solid 1px #d5dadc;
	  border-radius: 12px;
	  overflow: hidden;
	  clear: both;
  }




.message .self .text{
	/* flex: 1; */
	/* display:inline-block; */
	height:auto;
	width: fit-content;
    max-width: 100%;
	/* width: fit-content; */
	/* flex: 1; */
	/* max-width: 100%; */
	min-width: 0px;
	padding: 10px 10px;
	font-size: 14px;
	border-top-left-radius: 20px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    -webkit-border-top-left-radius: 20px;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-left-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    /* background: #f9f9f9; */
    /* color:#444444; */
    box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
	moz-box-shadow:    0px 2px 5px rgba(50, 50, 50, .3);
  	-webkit-box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
/* //    box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07); */
	white-space: pre-line;
}

.message .others .text{
	/* display:table; */
	height:auto;
	width: fit-content;
    max-width: 100%;
	/* width: fit-content; */
	/* max-width: 100%; */
	min-width: 0px;
	padding: 10px 10px;
	font-size: 14px;
	border-top-left-radius: 0px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    -webkit-border-top-left-radius: 0px;
    -webkit-border-top-right-radius: 20px;
    -webkit-border-bottom-left-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    /* background: #f2f2f2; */
    /* color:#444444; */
    box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
	moz-box-shadow:    0px 2px 5px rgba(50, 50, 50, .3);
  	-webkit-box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
/* //    box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07); */
	white-space: pre-line;
}



















.archiveMessage{
	width:100%;
	height: auto;
	padding: 5px 2px;
}
.archiveMessage > .divider{
	width:100%;
	height: auto;
	display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

	align-items: center;
	
	flex-direction: row;
}
.archiveMessage > .divider .dividerline{
	/* width:100%; */
	flex : 1;
	height: 1px;
	/* display: inline-block; */
}
.archiveMessage > .divider .dividertext{
	width: auto;
	padding: 0 40px;
	/* width:100%; */
	/* height: 2px; */
	/* display: inline-block; */
}
.archiveMessage > .self{
	width:auto;
	max-width: 80%;
	height: auto;
	overflow: visible;
	float: right;
	display: -ms-flexbox;
    display: -webkit-flex;
	display: flex;
	/* justify-content: flex-end; */
	flex-direction: column;
	/* justify-content: right; */
    align-items: flex-end;
}
.archiveMessage > .others{
	width:auto;
	max-width: 80%;
	height: auto;
	overflow: visible;
	float: left;
	display: -ms-flexbox;
    display: -webkit-flex;
	display: flex;
	/* justify-content: flex-start; */
	flex-direction: column;
	/* justify-content: right; */
    align-items: flex-start;
}

.archiveMessage .buffer{
	height:15px;
	width:100%;
}

.archiveMessage .sender{
	height:auto;
	width:auto;
	padding-top:15px;
	display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

	align-items: center;
	
	flex-direction: row;
}
.archiveMessage .self .sender{
	justify-content: flex-end;
}
.archiveMessage .others .sender{
	justify-content: flex-start;
}
.archiveMessage .sender > div{
	height:auto;
	width:auto;	
	/* display: inline-block; */
}
.archiveMessage .sender > .sendertext{
	padding:0 20px 10px;
}
.archiveMessage .sender > .sendertext > .sendername{
	font-size:13px;
	font-weight: 500;
	font-family: 'hurme1bold';
}
.archiveMessage .sender > .sendertext > .sendertime{
	padding-top: 5px;
	font-size:10px;
	font-family: 'hurme1ob';
}
.archiveMessage .sender > .senderimage{
	padding-bottom: 5px;
}
.archiveMessage .sender > .senderimage > img{
	border-radius: 50%;
	background-position: 50%;
}


.archiveMessage .self .textNotif{
	height:auto;
	width: fit-content;
    max-width: 100%;
	min-width: 0px;
	font-size: 13px;
	border-top-left-radius: 20px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    -webkit-border-top-left-radius: 20px;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-left-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    background: #e2e2e2;
    color:#444444;
    /* box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
	moz-box-shadow:    0px 2px 5px rgba(50, 50, 50, .3);
  	-webkit-box-shadow: 0px 2px 5px rgba(50, 50, 50, .3); */
/* //    box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07); */
}
.archiveMessage .self .textNotifHeader{
	height:auto;
	width: 100%;
	max-width: auto;
	min-width: 0px;
	background: #e73c33;
    color:#ffffff;
    padding: 5px 10px;
    font-size: 11px;
    text-transform: uppercase;
    font-family: 'hurme1bold';
}



.dark-theme .archiveMessage .self .textNotif{
	height:auto;
	width: fit-content;
    max-width: 100%;
	min-width: 0px;
	font-size: 13px;
	border-top-left-radius: 20px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    -webkit-border-top-left-radius: 20px;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-left-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    background: #465059;
    color:#ffffff;
    /* box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
	moz-box-shadow:    0px 2px 5px rgba(50, 50, 50, .3);
  	-webkit-box-shadow: 0px 2px 5px rgba(50, 50, 50, .3); */
/* //    box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07); */
}
.dark-theme .archiveMessage .self .textNotifHeader{
	height:auto;
	width: 100%;
	max-width: auto;
	min-width: 0px;
	background: #797ff2;
    color:#ffffff;
    padding: 5px 10px;
    font-size: 11px;
    text-transform: uppercase;
    font-family: 'hurme1bold';
}



.archiveMessage .self .textNotifMessage{
	height:auto;
	width: auto;
	max-width: auto;
	min-width: 0px;
    padding: 10px 10px;
    white-space: pre-line;
}




.archiveMessage .self .textAction{
	height:auto;
	width: fit-content;
    max-width: 100%;
	min-width: 0px;
	font-size: 13px;
	border-top-left-radius: 20px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    -webkit-border-top-left-radius: 20px;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-left-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    background: #f9f9f9;
    color:#444444;
    /* box-shadow: 0px 1px 2px rgba(50, 50, 50, .3);
	moz-box-shadow:    0px 1px 2px rgba(50, 50, 50, .3);
	  -webkit-box-shadow: 0px 1px 2px rgba(50, 50, 50, .3); */
/* //    box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07); */
}
.archiveMessage .self .textActionHeader{
	height:auto;
	width: 100%;
	max-width: 100%;
	min-width: 0px;
	flex: 0 0 100%;
	/* background: #e8e8e8; */
    color:#111111;
    padding: 10px 10px;
    font-size: 12px;
    /* text-transform: uppercase; */
    font-family: 'hurme1regular';
}

.dark-theme .archiveMessage .self .textAction{
	height:auto;
	width: fit-content;
    max-width: 100%;
	min-width: 0px;
	font-size: 13px;
	border-top-left-radius: 20px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    -webkit-border-top-left-radius: 20px;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-left-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    background: #2A3740;
    color:#eeeeee;
    /* box-shadow: 0px 1px 2px rgba(50, 50, 50, .3);
	moz-box-shadow:    0px 1px 2px rgba(50, 50, 50, .3);
	  -webkit-box-shadow: 0px 1px 2px rgba(50, 50, 50, .3); */
/* //    box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07); */
}
.dark-theme .archiveMessage .self .textActionHeader{
	height:auto;
	width: 100%;
	max-width: 100%;
	min-width: 0px;
	flex: 0 0 100%;
	/* background: #e8e8e8; */
	/* color:#111111; */
	/* background: #797ff2; */
    color:#ffffff;
    padding: 10px 10px;
    font-size: 12px;
    /* text-transform: uppercase; */
    font-family: 'hurme1regular';
}

.archiveMessage .self .textActionMain{
	height:auto;
	width: auto;
	max-width: 100%;
	min-width: 0px;
	padding: 0px 0px;
	white-space: nowrap;
    /* white-space: pre-line; */
}


.archiveMessage .self .textActionMainChoiceTextTab{
	height:auto;
	width: auto;
	max-width: 100%;
	min-width: 0px;
	padding: 10px 10px;
	border-top: 1px solid #e8e8e8;
	cursor: pointer;
	white-space: normal;
    /* white-space: pre-line; */
}
.dark-theme .archiveMessage .self .textActionMainChoiceTextTab{
	height:auto;
	width: auto;
	max-width: 100%;
	min-width: 0px;
	padding: 10px 10px;
	border-top: 1px solid #aaaaaa;
	cursor: pointer;
    /* white-space: pre-line; */
}
.archiveMessage .self .textActionMainChoiceTextTab .actionTabChoiceTextTitle{
	height:auto;
	width: auto;
	max-width: 100%;
	min-width: 0px;
	font-size: 13px;
	font-family: 'hurme1semibold';
	text-overflow: ellipsis;
    /* white-space: pre-line; */
}
.archiveMessage .self .textActionMainChoiceTextTab .actionTabChoiceTextDate{
	height:auto;
	width: auto;
	max-width: 100%;
	min-width: 0px;
    /* white-space: pre-line; */
}
.archiveMessage .self .textActionMainChoiceTextTab .actionTabChoiceTextStatus{
	height:auto;
	width: auto;
	max-width: 100%;
	min-width: 0px;
	font-size: 9px;
    /* white-space: pre-line; */
}

.archiveMessage .self .textActionMainOrderTab{
	height:auto;
	width: auto;
	max-width: auto;
	min-width: 0px;
	padding: 10px 10px;
	border-top: 1px solid #e8e8e8;
	cursor: pointer;
    /* white-space: pre-line; */
}
.dark-theme .archiveMessage .self .textActionMainOrderTab{
	height:auto;
	width: auto;
	max-width: auto;
	min-width: 0px;
	padding: 10px 10px;
	border-top: 1px solid #aaaaaa;
	cursor: pointer;
    /* white-space: pre-line; */
}
.archiveMessage .self .textActionMainOrderTab .actionTabOrderTitle{
	height:auto;
	width: 100%;
	max-width: auto;
	min-width: 0px;
	font-size: 13px;
	text-overflow: ellipsis;
    /* white-space: pre-line; */
}
.archiveMessage .self .textActionMainOrderTab .actionTabOrderDate{
	height:auto;
	width: auto;
	max-width: auto;
	min-width: 0px;
	margin-top: 5px;
    /* white-space: pre-line; */
}
.archiveMessage .self .textActionMainOrderTab .actionTabOrderStatus{
	height:auto;
	width: auto;
	max-width: auto;
	min-width: 0px;
	font-size: 9px;
    /* white-space: pre-line; */
}



.archiveMessage .self .text{
	/* flex: 1; */
	/* display:inline-block; */
	height:auto;
	width: fit-content;
    max-width: 100%;
	/* width: fit-content; */
	/* flex: 1; */
	/* max-width: 100%; */
	min-width: 0px;
	padding: 10px 10px;
	font-size: 13px;
	border-top-left-radius: 20px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    -webkit-border-top-left-radius: 20px;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-left-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    /* background: #f9f9f9; */
    /* color:#444444; */
    /* box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
	moz-box-shadow:    0px 2px 5px rgba(50, 50, 50, .3);
  	-webkit-box-shadow: 0px 2px 5px rgba(50, 50, 50, .3); */
/* //    box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07); */
	white-space: pre-line;
}

.archiveMessage .others .text{
	/* display:table; */
	height:auto;
	width: fit-content;
    max-width: 100%;
	/* width: fit-content; */
	/* max-width: 100%; */
	min-width: 0px;
	padding: 10px 10px;
	font-size: 13px;
	border-top-left-radius: 0px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    -webkit-border-top-left-radius: 0px;
    -webkit-border-top-right-radius: 20px;
    -webkit-border-bottom-left-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    /* background: #f2f2f2; */
    /* color:#444444; */
    /* box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
	moz-box-shadow:    0px 2px 5px rgba(50, 50, 50, .3);
  	-webkit-box-shadow: 0px 2px 5px rgba(50, 50, 50, .3); */
/* //    box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07); */
	white-space: pre-line;
}
















/* Mobile */
		@media only screen and (max-device-width: 480px) {
			 						
						.leftmain{
							width: 100%;
							height:100%;
							display:block;
						}
						.thread{
							
							width: 100%;
							text-align: center;
						}
						.middlemain{
							width: 100%;
							height:100%;
							display: none;
						}

						.chatinput .backbuttonbox{
							position: absolute;
							left:0;top:0;bottom:0;
						    height: 100%;
						    width: 90px;
						    padding: 0 5px 0 10px;
						}
						.chatinput .aibuttonbox{
							position: absolute;
							left:90px;top:0;bottom:0;
						    height: 100%;
						    width: 60px;
						    padding: 0 5px 0 5px;
						}
						.chatinput .inputbuttonbox{
							position: absolute;
							right:0;top:0;bottom:0;
						    height: 100%;
						    width: 90px;
						    padding: 0 10px 0 5px;
						}
						.chatinput .inputbox{
							position: absolute;
							left:150px;right:90px;top:0;bottom:0;
						    height: 100%;
						    padding: 0 5px 0 5px;
						}


				}	


				.image-slider {
					margin-top: 15px;
					margin-bottom: 10px;
					padding: 5px;
					display: flex;
					flex-direction: row;
				}
				.heading-12 {
					width: 100%;
					float: left;
				}
				div.scrollmenu-slideimage {
					overflow: auto;
					white-space: nowrap;
				}
				div.scrollmenu-slideimage::-webkit-scrollbar {
					width: 5px;
					height: 5px;
					border-radius: 5px;
					background: brown;
				}

				.demo-slideimage {
					opacity: 1;
					width: 70px;
					height: 72px;
					max-width: 70px;
					margin-right: 15px;
					border-radius: 10%;
					border: 2px solid #cec9c9;
				}
				.avatar {
					vertical-align: middle;
					width: 40px;
					height: 40px;
					/* border-radius: 50%; */
					cursor: pointer;
				}

.messageBox-mp {
  position: fixed;
  top: 0px;
  width: 60%;
  left: 18%;
  right: 22%;
  height: 100%;
  z-index: 9;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  overflow: hidden;
}
.messageBox-Background {
  width: 40%;
  height: fit-content;
  background: #eeeeee;
  margin: auto;
  padding: 2rem;
  border-radius: 20px;
}
.messageBox-text {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4c4747;
  font-weight: 600;
  font-size: 22px;
  /* padding: 0 0 1.5rem; */
}			
.messageBox-btn{
  display: flex;
  /* grid-template-columns: 1fr 1fr;
  grid-gap: 1rem; */
  height: 2.5rem;
  justify-content: center;
}
.messageBox-btn > div {
  width: 25%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #6b5a5a;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 12px;
}
.messageBox-btn > button {
	width: 25%;
	height: 100%;
	background: #ffffff;
	border: 1px solid #6b5a5a;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin: 0 12px;
  }

.messageBox-btn > button:disabled{
    border: 1px solid #999999;
    /* background-color: #d6cdcd; */
    color: #666666;
    cursor: not-allowed;
    background-color: #ccc !important;
}
.hover-box{
	width: 575px;
    left: 20%;
    bottom: 13%;
    height: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 16px;
	margin: 0 0 10px;
	padding: 24px;
	position: absolute;
	border-radius: 16px;
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
	background-color: #212b35;
}
.s-loader{
	width: 20% !important;
    height: 100% !important;
}
.hover-box:after{
    content: ' ';
    display: block;
    position: absolute;
    left:40px;
    bottom: -6px;
    width: 14px;
    height: 14px;
    border-color: #212b35;
    border-width: 1px;
    border-style: none none solid solid;
    background-color: #212b35;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
}
.flex-box-row{
	display: flex;
	flex-direction: row;
	align-items: center;
	width: -webkit-fill-available;
	justify-content: space-between;
}
.refund-error{
	color: red;
	text-align: left;
}
.header-customer-box{
	color: #fff;
	font-size: 24px;
	font-weight: 600;
}
.custom-box-label{
	font-size: 18px;
	color: rgba(255, 255, 255, 0.6);
}
.customer-box-value{
	color: rgba(255, 255, 255, 0.96);
	font-size: 18px;
}
.loader-hc {
	width: 16px;
	height: 16px;
	border-radius: 50%;
	border: 4px solid #e09696;
	border-top: 4px solid red;
	margin: 0rem 2.5rem;
	animation: spin 1.5s linear infinite;
  }
  @keyframes spin {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }
  @media (max-width: 768px) {
	.loader-hc {
	  width: 12px;
	  height: 12px;
	  border-radius: 50%;
	  border: 4px solid #ffffff;
	  border-top: 4px solid red;
	  margin: 0.1rem 2rem;
	  animation: spin 1.5s linear infinite;
	}
	@keyframes spin {
	  0% {
		transform: rotate(0deg);
	  }
	  100% {
		transform: rotate(360deg);
	  }
	}
  }

  .flexContainerCenter{
	  display: flex;
	  flex-direction: column;
	  justify-content: center;
	  margin-right: 10px;
  }
  .lastOrderDetailContainer{
	border-radius: 4px;
    border: solid 2px #dddddd;
	display: flex;
	flex-direction: column;
	padding: 4% !important;
    width: 82% !important;
    margin: 0 5%;
	box-shadow: 0 0 8px 0 rgb(0 0 0 / 12%);
    border: solid 1px rgba(196, 196, 196, 0.4);
    /* background-color: #fff; */
  }
  .chatTransfer{
	  display:flex;
	  flex-direction: row;
	  gap:15px;
	  align-items: center;
  }
  .transferDropdown{
	  width:70% !important;
	  height:32px !important;	
  }
  .archiveButton{
	  width:30% !important;
  }
  .userDetailInner{
	display: flex;
	flex-direction: row;
	align-items: center;
  }
  .userDetailItem1{
	/* flex-grow: 1; */
	margin: 0 8px 0 0;
	font-size: 14px;
	font-weight: 500;
	color: #999999;
	width: auto !important;
	text-transform: capitalize;
	font-family: 'hurme1bold';
  }
  .userDetailItem2 {
  	font-size: 14px;
	width: auto !important;
	font-family: 'hurme1semibold';
  	/* color: #565656; */
  }
  .customer-name{
	height: auto;
	text-transform: capitalize !important;
	font-size: 24px !important;
	font-weight: bold;
	font-family: 'hurme1bold' !important;
	text-align: left;
	margin-bottom: 0 !important;
	line-height: 1.5;
  }
  .flexBox {
	  display: flex;
	  align-items: center;
  }
  .mobileNum {
	font-size: 15px !important;
    color: #666666 !important;
    font-weight: 500;
	line-height: 1.5;
	text-align: left;
  }
  .orderStatus1 {
	display: flex;
	align-items: center;
	width: 40% !important;
	font-size: 14px !important;
	line-height: 1.5;
	text-align: left;
	margin: 0 5px 0 0;
  }
  .goAppMoney{
	  text-align:left;
	  display: flex;
	  padding-top: 8px !important;
	  flex-direction: row;
	  white-space: nowrap;
  }
  .orderStatus2 {
	display: flex;
	align-items: center;
	/* width: auto !important; */
	font-size: 14px !important;
	line-height: 1.5;
	text-align: left;
	margin: 0 5px 0 0;
  }
  .orderHead {
	  font-size: 22px !important;
	  margin-bottom: 0 !important;
  }
  .copyImg {
	height: 15px;
    width: 15px;
    margin-left: 8px;
	object-fit: contain;
	cursor: pointer;
  }
  .downloadBill {
	color: #E73C33;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.25px;
	text-decoration: underline;
	text-align: left;
	cursor: pointer;
  }
  .downloadBill2 {
	color: #797FF2;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.25px;
	text-decoration: underline;
	text-align: left;
	cursor: pointer;
  }
  .orderMain {
	  margin-bottom: 20px;
	  display: flex;
	  flex-direction: column;
  }
  .orderSecondBox {
	  display: flex !important;
	  flex-direction: column;
	  padding: 0.75rem;
	  height: auto !important;
  }
  .orderMiddle {
	  display: flex !important;
	  align-items: center;
  }
  .orderLastLine {
	  display: grid !important;
	  grid-template-columns: 1fr 1fr;
	  grid-gap: 0.5rem;
	  align-items: center;
	  margin-top: 5px;
  }
  .orderButton {
	  width: 90% !important;
	  margin: 0 5% 5%;
  }
  .car-logo {
	height: 60px;
    width: 80px;
  }
  .regNo {
	  color: #666666 !important;
	  font-size: 13px;
	  font-weight: 600;
	  white-space: nowrap;
	  overflow: hidden!important;
	  text-overflow: ellipsis;
	  letter-spacing: 0.2px;
	  padding: 2px;
  }
  .regNo1 {
	/* color: #dddddd !important; */
	font-size: 13px;
	font-weight: 600;
	white-space: nowrap;
	overflow: hidden!important;
	text-overflow: ellipsis;
	letter-spacing: 0.2px;
	padding: 2px;
}
  .carName {
	color: #666666 !important;
	font-size: 13px;
	font-weight: 600;
	margin-top: 5px;
	white-space: nowrap;
    overflow: hidden!important;
    text-overflow: ellipsis;
	padding: 2px;
  }
  .carName1 {
	/* color: #dddddd !important; */
	font-size: 13px;
	font-weight: 600;
	margin-top: 5px;
	white-space: nowrap;
    overflow: hidden!important;
    text-overflow: ellipsis;
	padding: 2px;
  }
  .serviceType {
	color: #666666 !important;
	font-size: 14px;
	font-weight: 600;
	white-space: nowrap;
	overflow: hidden!important;
	text-overflow: ellipsis;
	letter-spacing: 0.2px;
  }
  .serviceType1 {
	font-size: 14px;
	font-weight: 600;
	white-space: nowrap;
	overflow: hidden!important;
	text-overflow: ellipsis;
	letter-spacing: 0.2px;
	color: #dddddd;
	font-weight: 600;
}
  .lastOrderDetailContainer > div{
	  padding-top: 2px;
	  padding-bottom: 2px;
  }
  .orderIdMain {
	  width: 100% !important;
	  display: flex !important;
	  flex-direction: column;
  }
  .orderIdText {
	  width: 100% !important;
	  color: #999999 !important;
	  font-weight: 600;
	  font-size: 14px;
	  margin-top: 12px;
	  font-family: 'hurme1bold';
  }
  .orderIdText1 {
	width: 100% !important;
	color: #cccccc !important;
	font-weight: 500;
	font-size: 14px;
	margin-top: 12px;
}
  .orderIdSubText {
	width: 100% !important;
	color: #666666 !important;
	font-weight: 600;
	font-size: 14px;
	margin-top: 5px;
	font-family: 'hurme1bold';
  }
  .orderIdSubText1 {
	width: 100% !important;
	color: #cccccc !important;
	font-weight: 600;
	font-size: 14px;
	margin-top: 5px;
	font-family: 'hurme1bold';
  }
  .tooltip {
	position: relative;
	display: inline-block;
  }
  
  .tooltip .tooltiptext {
	visibility: hidden;
	width: 140px;
	background-color: #555;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px;
	position: absolute;
	z-index: 1;
	bottom: 150%;
	left: 50%;
	margin-left: -75px;
	opacity: 0;
	transition: opacity 0.3s;
  }
  
  .tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #555 transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
  }
  .amc-strip{
	background-color: #383C3F;
    border-radius: 5px;
	padding-top: 4px;
	padding-bottom: 2px;
  }
  .amc-label{
	font-size: 18px;
    text-align: right;
    font-weight: 700;
    padding: 5px;
    color: white;
	font-family: 'hurme1bold';
  }
  .amc-strip-dark{
	border-radius: 5px;
	padding-top: 4px;
	padding-bottom: 2px;
	background-color: #F1554E;
  }
  .cancel-btn{
	font-size: 12px !important;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-family: 'hurme1semibold';
    cursor: pointer;
  }
  .voucher-heading{
	height: auto;
	text-transform: capitalize !important;
	font-size: 18px !important;
	font-weight: bold;
	font-family: 'hurme1bold' !important;
	text-align: left;
	margin-bottom: 0 !important;
	line-height: 1.5;
  }
  	.orderDetailInner {
		display: flex !important;
		flex-direction: row;
		align-items: center;
		padding: 3px;
	}
	.orderDetailItem1 {
		/* flex-grow: 1; */
		margin: 0px 8px 0 0;
		font-size: 14px;
		font-weight: 500;
		color: rgba(0, 0, 0, 0.54);
		width: auto !important;
		text-transform: capitalize;
		font-family: 'hurme1bold';
	}
	.orderDetailItem2 {
		font-size: 14px;
		width: auto !important;
		font-family: 'hurme1semibold';
		color: #565656;
		/* color: #666; */
	}
	.ddspan .orderDetailItem1{
		color: #bbbbbb;
	}
	.ddspan .orderDetailItem2{
		color: #bbbbbb;
	}
	.actionDetailItem1{
		margin: 0px 8px 0 0;
		font-size: 14px;
		font-weight: 500;
		/* color: black; */
		width: auto !important;
		text-transform: capitalize;
		font-family: 'hurme1bold';
	}
	.actionDetailItem2 {
		font-size: 14px;
		width: auto !important;
		font-family: 'hurme1semibold';
		color: red;
	}
	.agentCommentButtonContainer{
		display: flex !important;
		flex-direction: row;
		padding: 0.25rem 0.75rem 0.75rem 0.75rem;
		height: 100% !important;
	}
	.actionsButtonContainer{
		display: flex !important;
		flex-direction: column;
		padding: 0 0.75rem 0.75rem 0.75rem;
		height: 100% !important;
	}
	.actionsButtonContainer > button{
		margin-top: 10px;
		cursor: pointer;
		background: rgba(196, 196, 196, 0.2);
		outline: none;
		border: none;
		color: black;
		font-weight: bold;
		border-radius: 5px;
		padding: 6px 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		/* transition: transform 0.5s; */
		width: 100%;
		/* box-shadow: 0px 2px 5px rgb(50 50 50 / 30%); */
		font-size: 14px !important;
		/* transition-property: box-shadow, transform; */
		/* transition-duration: .15s; */
		margin: 10px 0 0 0;
		height: 40px;
	}
	.agentCommentButtonContainer > button{
		cursor: pointer;
		width: auto;
		height: 33px;
		flex-grow: 0;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 10px;
		padding: 8px 16px;
		border-radius: 8px;
		border: solid 1.4px #d54b3d;
		/* font-family: Gilroy; */
		font-size: 14px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #d54b3d;
		background-color: #ffffff;
	}
	.ddspan .actionsButtonContainer > button{
		color: #fff;
	}
	.view-more{
		margin: 0 5px;
		width: 8px;
		height: 8px;
		flex-grow: 0;
		object-fit: contain;
		transform: rotate(0deg);
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.67;
		letter-spacing: normal;
		text-align: left;
		color: #d54b3d;
	}
	.expand{
		transform: rotate(180deg);
	}
	.orderPackages{
		margin-top: 10px;
		display: flex !important;
		flex-direction: row;
		justify-content: space-between;
		padding: 0.20rem;
	}
	.orderPackages > div > div{
		padding: 3px;
	}
	.orderDetailItem3{
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.orderDates{
		margin-top: 10px;
		display: flex !important;
		flex-direction: row;
		justify-content: space-between;
    	padding: 6px 8px;
		border-radius: 6px;
		border: solid 1px rgba(0, 0, 0, 0.2);
	}
	.ddspan .orderDates{
		border: solid 1px #bbbbbb;
	}
	.orderDates > div > div {
		padding: 4px;
	}
	.actionsButtonContainer > button:hover{
		/* transform: scale(1.1); */
		color: #ffffff;
		font-weight: 600;
		background-color: #e73c33;
	}
	.orderStatus{
		list-style-type: disc;
		/* list-style-position: inside; */
		display: list-item;
		margin: 14px 0 13px 10px;
	}
	.orderStatusComplete{
		color: #30990b !important;
	}
	.orderStatusDelay{
		color: #c52026 !important;
	}
	.orderStatusOngoing{
		color: #ffa500 !important;
	}
  .map-textarea{
    background-color: #fff;
    border-radius: 2px !important;
    font-size: 15px !important;
    margin: 0 !important;
  }
  .map-textarea:disabled{
    border: 1px solid #999999;
    /* background-color: #d6cdcd; */
    color: #666666;
    cursor: not-allowed;
    background-color: #ccc !important;
	}
	.contactIcon{
		height: 15px;
		width: 15px;
		margin-right: 8px;
		object-fit: contain;
	}
	.rupeeIcon{
		margin-right: 8px;
		object-fit: contain;
	}
	.moneyIcon{
		margin-right: 10px;
		margin-left: 2px;
		color:#aaaaaa;
		width: fit-content !important;
	}
	.orderNoLabel{
		width: 30%;
		font-size: 9px;
		height: 12px;
		color: #fff;
		padding: 6px;
		border-radius: 6px;
		background-color: #0c68cd;
	}
	.orderRepeatedLabel{
		height: 18px !important;
	}
	.scroll-container{
		position: absolute;
		transition: opacity .3s;
		bottom: 60px;
		/* transform: translatex(-50%); */
		z-index: 200;
		cursor: pointer;
		right: 5px;
		display: inline-flex;
		flex-direction: column;
		width: 60px;
		border-radius: 50%;
		/* height: 100%; */
	}
	.scroll-button{
		width: 40px;
		height: 40px;
		margin-left: 15px;
		border: 1px solid #565656;
		border-radius: 100%;
		box-sizing: border-box;
	}
	.scroll-button::after{
		position: absolute;
		top: 50%;
		/* left: 50%; */
		content: '';
		width: 16px;
		height: 16px;
		margin: -12px 0 0 -8px;
		border-left: 2px solid #565656;
		border-bottom: 2px solid #565656;
		transform: rotate(-45deg);
		box-sizing: border-box;
	}
	.unread-message{
		left: 5px;
		position: absolute;
		top: -6px;
	}
	.unread-number{
		transform: scaleX(1) scaleY(1);
		opacity: 1;
		padding: .3em .4em;
		text-align: center;
		background-color: #e73c33;
		border-radius: 1.1em;
		min-width: .8em;
		font-weight: 500;
		display: inline-block;
		min-width: .9em;
		min-height: 1em;
		font-size: 12px;
		font-weight: 600;
		line-height: 1em;
		color: white;
		vertical-align: top;
	}
	.scroll-container:hover{opacity:.4;}
	.dark-theme .scroll-button{
		border: 1px solid white;
	}
	.dark-theme .scroll-button::after{
		border-left: 2px solid white;
		border-bottom: 2px solid white;
	}
	.orderBoxStatus{
		display: flex !important;
		align-items: center !important;
		width: 40% !important;
		line-height: 1.5 !important;
		text-align: right !important;
    	margin: 0 5px 0 0;
		justify-content: flex-end !important;
	}
	.agent-note{
		text-transform: inherit !important;
		background-color: #fffee5;
		color: #8b897c;
		border: none;
		outline: none;
		font-weight: bold;
		border-radius: 5px;
		padding: 6px 8px;
		width: 95% !important;
		box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
		font-size: 14px !important;
		margin-top: 10px;
	  }
	  .quick-buttons{
		  position: sticky;
		  bottom: 0;
		  display: flex;
		  gap: 30px;
		  padding: 30px;
	  }
	  .quick-button{
		height: 32px;
		flex-grow: 0;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 4px;
		padding: 6px 12px;
		border-radius: 4px;
		border: solid 1px rgba(0, 0, 0, 0.12);
		background-color: #fff;
		font-family:'hurme1regular', sans-serif;
		font-size: 12px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.58;
		letter-spacing: normal;
		text-align: left;
		color: #2170d9;
		cursor: pointer;
	  }
	  .quick-button:hover{
		color: #fff;
		background-color:#2170d9;
	  }
	  .track-acc-button{
		  width: -webkit-fill-available;
		  font-family: 'hurme1regular';
		  font-size: 14px;
		  font-weight: 600;
		  color: #fff;
		  height: 36px;
		  display: flex;
		  flex-direction: row;
		  justify-content: center;
		  align-items: center;
		  gap: 6px;
		  padding: 8px;
		  border: none;
		  border-radius: 4px;
		  background-color: #5195e0;
		  margin: 12px 0px;
		  cursor: pointer;
	  }
	  .share-live{
		width: -webkit-fill-available;
		font-family: 'hurme1regular';
		font-size: 14px;
		font-weight: 600;
		color: #fff;
		height: 36px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 6px;
		padding: 8px;
		border-radius: 4px;
		border: none;
		background-color: #4caf50;
		cursor: pointer;
	  }
	  .w-full{ 
		  width:-webkit-fill-available;
	  }
	  .main-escalation-box{
		display: flex;
		flex-direction: column;
		padding: 4% !important;
		width: 82% !important;
		margin: 5% 0;
		box-shadow: 0 0 8px 0 rgb(0 0 0 / 12%);
		border-radius: 8px;
		border: solid 1px #d54b3d;
		background-color: rgba(213, 75, 61, 0.08);  
		text-align: left !important;
		text-transform: none !important;
	  }
	  .live-esc-pop-up{
		position: absolute;
		flex-grow: 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 16px;
		right: 50px;
		bottom: 150px;
		width: 75% !important;
		margin: 0 0 14px;
		padding: 16px;
		border-radius: 16px;
		text-transform: none !important;
		box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
		background-color: #212b35;
	  }
	  .live-p-relative{
			position: relative;
	  }
	  .live-esc-pop-up:after{
		content: ' ';
		display: block;
		position: absolute;
		right: 23px;
		bottom: -6px;
		width: 14px;
		height: 14px;
		border-color: #212b35;
		border-width: 1px;
		border-style: none none solid solid;
		background-color: #212b35;
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
	  }
	  .live-esc-flex-row{ 
		  display: flex;
		  flex-direction: row;
		  align-items: center;	
		  justify-content: space-between !important;			  
	  }
	  .live-escalation-header-text{
		font-size: 18px !important;
		color: #e73c33;
		font-weight: bold;
		padding-bottom: 8px;
	  }
	  .live-escalation-header{
		  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
	  }
	  .live-esc-button{
		width: 100px;
		height: 25px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 8px;
		border-radius: 6px;
		background-color: #e73c33;
		font-size: 12px;
		font-weight: 700;
		color: #fff;
		border: none;
		font-family: 'hurme1semibold';
	  }
	  .live-esc-subheader{
		font-size: 12px;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.6);
	  }
	  .live-esc-value{
		font-size: 12px;
		font-weight: 600;
		color: #000;
	  }
	  .live-second-part{
		  margin-top: 16px;
	  }
	  .live-text-right{
		  text-align: right;
	  }
	  .live-esc-flex-col{
		  gap: 4px;
		  display: flex;
		  flex-direction: column;
	  }
	  .live-esc-pop-up-header{
		font-size: 16px;
		font-weight: 600;
		text-align: left;
		color: #fff;
	  }
	  .live-esc-pop-up-label{
		font-size: 14px;
		font-weight: 600;
		text-align: left;
		color: rgba(255, 255, 255, 0.6);
	  }
	  .live-esc-pop-up-value{
		font-size: 14px;
		font-weight: 500;
		text-align: right;
		color: rgba(255, 255, 255, 0.96);
	  }
.view-details-button{
	height: 20px;
		border-radius: 5px;
		background-color: rgba(231, 60, 51, 0.12);
		font-family: 'hurme1semibold';
		font-size: 12px;
		font-weight: 600;
		letter-spacing: normal;
		text-align: left;
		color: #d54b3d;
		padding:6px;
	  }
	  .view-details-button-dark{
		height: 20px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		border-radius: 5px;
		background-color:#797ff2;
		font-family: 'hurme1semibold';
		font-size: 12px;
		font-weight: 600;
		letter-spacing: normal;
		text-align: left;
		color: #fff;
		padding:6px;
	  }
	  .order-middle-row{
		  display:flex !important;
		  justify-content: space-between;
	  }