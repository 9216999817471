.send-voucher-btn {
  cursor: pointer;
  background: #e73c33;
  outline: none;
  border: 1px solid #e73c33;
  color: #ffffff;
  font-weight: bold;
  border-radius: 5px;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s;
  width: 100% ;
  box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
  font-size: 14px !important;
  transition-property: box-shadow, transform;
  transition-duration: .15s;
  margin-top: 10px;
  height: 40px;
}

.send-voucher-btn:hover {
  transform: scale(1.1);
  color: #ffffff;
  font-weight: 600;
}
.archive-button{
  margin-top:6px !important
}
.send-voucher-btn-dark {
  /* background-image: linear-gradient(45deg, #797ff2, #494fe8);  */
  background-color: #797ff2;
  color: #ffffff;
  border: none;
}

.voucher-textarea{
  background-color: #fff;
  border-radius: 2px !important;
  font-size: 15px !important;
}
.comments-label{
  text-transform: none;
  display: flex;
  margin-top: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  font-size: 15px;
}
.voucher-textarea:disabled,.send-voucher-btn:disabled{
    border: 1px solid #999999;
    /* background-color: #d6cdcd; */
    color: #666666;
    cursor: not-allowed;
    background-color: #ccc !important;
}
.voucher-sent{
  color: green;
  text-align: left;
}