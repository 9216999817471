.order-note{
  background-color: #fff6e8;
  color: #bb906c;
  border: none;
  outline: none;
  font-weight: bold;
  border-radius: 5px;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s;
  width: 95% !important;
  box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
  font-size: 14px !important;
  margin-top: 10px;
}
.order-input{
  border: solid 1px #d1d1d1 !important;
  width: 100% !important;
  max-width: 100% !important;
  font-family: 'hurme1regular',sans-serif !important;
  font-size: 15px !important;
  padding: .75rem !important;
  background-color: white !important;
  box-shadow: none !important;
  color: rgba(48, 48, 48, 0.87) !important;
}
.order-input:disabled{
  border: 1px solid #999999;
  color: #666666;
  cursor: not-allowed;
  background-color: #ccc !important;
}
.order-service-container{
  display: grid;
  grid-template-columns: 4fr 1fr;
  margin-top: 5px;
  align-items: center;
  /* border: 1px solid #999999; */
  color: #666666;
  cursor: not-allowed;
  /* background-color: #ccc !important; */
  border: solid 1px #c2cdff;
  background-color: #f7f9ff;
  border-radius: 4px;
}
.order-service{
  text-align: left;
  font-family: 'hurme1regular',sans-serif;
  font-size: 15px;
  padding: .75rem;
  color: rgba(48, 48, 48, 0.87);
}
.service-amount{
  display: flex;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #d54b3d;
}
.order-date-container{
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}
.order-date-container input:disabled{
  border: 1px solid #999999;
  /* background-color: #d6cdcd; */
  color: #666666;
  cursor: not-allowed;
  background-color: #ccc !important;
}
.loader-or {
	width: 16px;
	height: 16px;
	border-radius: 50%;
	border: 4px solid rgba(255, 192, 203, 0.605);
	border-top: 4px solid white;
	margin: 0rem 2.5rem;
	animation: spin 1.5s linear infinite;
  }
  @keyframes spin {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }

  .order-date{
    text-align: left;
    font-family: 'hurme1regular',sans-serif;
    font-size: 15px;
    padding: .75rem;
    color: rgba(48, 48, 48, 0.87);
    border: solid 1px #d0d0d0;
    outline: none;
    border-radius: 4px;
    width:-webkit-fill-available;
  }
  ul.dd-list {
    z-index: 2!important;
    list-style-type: none;
    margin: 1px;
    padding: 0;
    top: 50px;
    width: -webkit-fill-available;
    right: 0;
    background-color: #fff;
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
    max-height: 15rem;
    overflow: auto;
    font-size: 13px;
}

li {
  padding: 8px 16px;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
}
.global-search-auto-complete {
  color: #000;
  font-weight: 500;
  text-transform: capitalize;
}
.notification-message .message{
  padding: 0 !important;
}
.select-time{
margin-top: 10px !important;
font-size: 16px !important;
padding: 10px;
background: #fff !important;
color: #000 !important;
}