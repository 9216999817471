
/*   
    .contentContainer{
    padding: 10px 0 0 38px;
      position: relative;
    }
    .contentContainer .content {
      padding: 8px;
  }
  .order-status-name {
      color: #4a4a4a;
      text-transform: capitalize;
  }
  .font_14 {
      font-size: 14px!important;
  }
  .grayFont {
      color: #9b9b9b!important;
  }
  
  .font_10 {
      font-size: 10px!important;
  }
  .margin_tb_6 {
      margin-top: 6px!important;
      margin-bottom: 6px!important;
  }
   */
  .whiteBackground {
    background-color: #fff!important;
}

.margin_tb_20 {
    margin-top: 20px;
    margin-bottom: 20px;
}
  
  /*! CSS Used from: https://lead.gomechanic.app/static/css/main.afa54db1.chunk.css */
  .margin_tb_6{margin-top:6px!important;margin-bottom:6px!important;}
  .font_10{font-size:10px!important;}
  .font_14{font-size:14px!important;}
  .grayFont{color:#9b9b9b!important;}
w  /* ::-webkit-scrollbar{width:5px;}
  ::-webkit-scrollbar-track{background:#eee;}
  ::-webkit-scrollbar-thumb{background:#888;border-radius:10px;}
  ::-webkit-scrollbar-thumb:hover{background:#555;} */
  /*! CSS Used from: https://lead.gomechanic.app/static/css/15.ead6aec3.chunk.css */
  .orderTimelineContainerBox{min-height:20rem;border-radius:10px;background-color:hsla(0,0%,100%,.7);position: relative;}
  .order-status-name{text-transform:capitalize;}
  .contentContainer{padding:16px 0px 10px 60px;position:relative;}
  .contentContainer .content{padding:8px;}
  .current.contentContainer:before{background-color:#fff;-webkit-animation:pulse 2s infinite;animation:pulse 2s infinite;border: 1px solid #ff4355; content:"";position:absolute;left:18px;top:20px;z-index:2;border-radius:50%;width:14px;height:14px;}
  .done.contentContainer:before{background-color:#ff4355;}
  .contentContainer:before,.done.contentContainer:before{content:"";position:absolute;left:18px;top:20px;z-index:2;border-radius:50%;width:14px;height:14px;}
  .contentContainer:before{background-color:#d0d0d0;}
  .done.contentContainer:after{background-color:#ff4355;}
  .contentContainer:after,.done.contentContainer:after{content:"";padding:20px 0;position:absolute;width:2px;top:20px;bottom:-20px;left:24px;}
  .contentContainer:after{background-color:#d0d0d0;}
  .contentContainer:last-child:after{background-color:transparent;}
  .action-btn{font-size:12px;letter-spacing:normal;cursor:pointer;color:#6a82f5;margin:5px 0;}
  /*! CSS Used keyframes */
  @-webkit-keyframes pulse{0%{-webkit-transform:scale(.95);transform:scale(.95);box-shadow:0 0 0 0 rgba(255,67,85,.7);}70%{-webkit-transform:scale(1);transform:scale(1);box-shadow:0 0 0 10px rgba(255,67,85,0);}to{-webkit-transform:scale(.95);transform:scale(.95);box-shadow:0 0 0 0 rgba(255,67,85,0);}}
  @keyframes pulse{0%{-webkit-transform:scale(.95);transform:scale(.95);box-shadow:0 0 0 0 rgba(255,67,85,.7);}70%{-webkit-transform:scale(1);transform:scale(1);box-shadow:0 0 0 10px rgba(255,67,85,0);}to{-webkit-transform:scale(.95);transform:scale(.95);box-shadow:0 0 0 0 rgba(255,67,85,0);}}


  .accordion {
    background-color: #f6f6f6;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
    border-radius: 4px;
    box-shadow: 0 0 12px 0 rgb(0 0 0 / 8%);
    border: solid 1px rgba(209, 209, 209, 0.4);
  }
  .active,
  .accordion:hover {
    background-color: #f6f6f6;
  }
  .panel {
    display: none;
    /* background-color: white; */
    overflow: hidden;
    /* border: 1px solid hsla(0, 0%, 60.8%, 0.2); */
    border-radius: 4px;
    box-shadow: 0 0 12px 0 rgb(0 0 0 / 8%);
    border: solid 1px rgba(209, 209, 209, 0.4);
  }
  
  .active,
  .accordion:hover {
    background-color: #f6f6f6;
  }
  .FAQ {
    cursor: auto;
    width: 30% !important;
    /* height: 700px; */
    height: 800px;
    background-color: #ffffff;
    margin-left: 3%;
    cursor: auto;
    margin-right: 3.5%;
  }
  .set1 {
    margin: 20px;
  }
  .title2 {
    width: 100%;
    height: 15px;
    font-family: Gilroy;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 0.08px;
    text-align: left;
    color: #282828;
    margin-bottom: 20px;
  }
  .faq1 {
    width: 100%;
    height: 30px;
    object-fit: contain;
    background-color: #f6f6f6;
  }
  .faq-ques-1 {
    width: 75%;
    float: left;
    padding: 5px;
  }
  .img5 {
    width: 12px !important;
    height: 7.4px;
    object-fit: contain;
    margin: 5px;
    float: right !important;
  }
  .ans1-box {
    width: 100%;
    height: auto;
    border: 1px;
    background-color: #ffffff;
  }
  .faq-div {
    margin-top: 16px;
  }
  
  .box-see-all {
    width: 108px;
    height: 40px;
    border-radius: 2px;
    background-color: transparent;
    border: solid 1px rgba(155, 155, 155, 0.5);
  }
  .see-all {
    width: 100%;
    height: 100%;
    font-family: Gilroy;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: 0.14px;
    text-align: center;
    color: #282828;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  .faq-dis {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .faq-dis-1 {
    margin-top: 15px;
    height: 100%;
  }
  .faq-scroll-track {
    width: 100% !important;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: -4px;
    /* height: 470px; */
    /* height: 575px; */
    height: 500px;
    margin-bottom: 10px;
  }
  .faq-scroll-track::-webkit-scrollbar {
    width: 0px;
  }
  .faq-scroll-track::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .faq-scroll-track::-webkit-scrollbar-thumb {
    background: #888;
  }
  .faq-scroll-track::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .arrow {
    transition: transform 0.3s;
  }
  .rotated {
    transform: rotate(180deg);
  }
  
  /* ------Banner Image------- */
  .bannerImageTyre {
    /* width: 80%;
    height: 150px; */
    margin-bottom: 0.5rem;
  }
  
  .modal-sb {
    position: absolute;
    z-index: 5;
    background: rgba(0, 0, 0, 0.6);
    width: 100vw;
    overflow: hidden;
    top: 0;
    bottom: 0;
  }
  
  .sub-modal-sb {
    position: absolute;
    z-index: 6;
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    left: 40%;
    top: 48%;
    margin: auto 0;
    border: 8px solid #f3f3f3;
    border-top: 8px solid #e73c33;
    animation: spin 2s linear infinite;
  }
  .spares-date{ 
    width: -webkit-fill-available !important;
  }
  .timeline-header{
    border-top:1px solid #dddddd;
    font-size: 12px;
    width: -webkit-fill-available !important;
    font-weight: 600;
    padding-top: 10px;
    margin:20px;
  }
  .titleBottom{
    border-bottom: 1px solid;
  }
  .product-detail{
    margin:20px;
    width: -webkit-fill-available !important;
 }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  