.active-badge{
    width:-webkit-fill-available !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(219, 252, 207, 0.6);
    font-size: 12px;
    padding: 2px 10px !important;
    border-radius:5px;
    margin-top:4px;
    font-weight: bold;
    color: #099867;
}
.subs-flex{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.card-text-width{
    width:auto !important;
}
.date-details{
    font-weight: 500 !important;
}
.collapse-arrow{ 
    height:8px !important;
    width:10px !important;
}
.arrow-open{ 
    transform: rotate(180deg);
}
.bold-text{
    font-weight: 600 !important;
}
.car-details{
    text-align: left !important;
    font-weight: 500 !important;
}
.subscription-header{
    margin-bottom: 0px !important;
}
.header-right{
    width:auto !important;
}
.subscription-content{
    margin-top: 4% !important;
    border-top: 1px solid #dddddd;
}
.total-amount{
   padding-top: 4% !important;
   margin-top: 4% !important;
    width:auto !important;
    border-top: 1px solid #dddddd;
}
.purchase-detail{
    padding-top: 4% !important;
    border-top: 1px solid #dddddd;
}
.table-header{
    flex-grow: 0;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #757575; 
    padding: 8px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.12);
    background-color:rgba(0, 0, 0, 0.12);
}
.table-content{ 
    padding:8px;
}
.car-image{
    padding:6px;
    height:70px !important;
}
.coverage-table{ 
    border-radius: 6px;
    border: solid 1px rgba(0, 0, 0, 0.12);
    background-color: #fff;
    margin:12px 0px !important;
    padding:0px !important;
}
.header-left{ 
    margin-left:auto;
    display: flex;
    flex-direction: row;
    width:auto !important;
}
.expired-badge{
    width:-webkit-fill-available !important;
    margin-top:4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: rgba(250, 211, 209, 0.6);  
    font-size: 12px;
    font-weight: bold;
    color: #e73c33;
    padding: 2px 10px !important;
}
.subs-card{
    overflow-y: scroll;
    max-height:415px;
}