.add-escalation-es {
  cursor: pointer;
  background: #e73c33;
  outline: none;
  border: 1px solid #e73c33;
  color: #ffffff;
  font-weight: bold;
  border-radius: 5px;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s;
  width: 100% !important;
  box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
  font-size: 14px !important;
}

.add-escalation-es:hover {
  transform: scale(1.1);
  color: #ffffff;
  font-weight: 600;
}

.modalComponent {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  width: 100% !important;
  height: 100% !important;
}
  
.modalBackground {
  display: flex;
  position: fixed;
  z-index: 10;
  top: 2.5rem;
  right: 0;
  left: 0;
  bottom: 0;
  width: 80% !important;
  max-height: 80% !important;
  height: 80% !important;
  border-radius: 10px;
  background: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  margin: auto;
  box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
}

.escalation-es {
  color: #212121;
  font-size: 1.25rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  text-align: left;
}

.inputBoxes-es {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 2rem;
}

.box1-es {
  width: 100%;
  height: 48px;
  border-radius: 10px;
  border: 1px solid #d0d0d0;
  background-color: hsla(0,0%,100%,.36);
}

.box1-es > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 4px 3%;
  align-items: flex-start;
}

.inputTitle-es {
  font-size: 11px;
  color: #9b9b9b;
}

.mainInput-es {
  color: #000000 !important;
  cursor: auto !important;
  background: none !important;
  box-shadow: none !important;
  margin: unset !important;
  padding: unset !important;
  line-height: unset !important;
  font-size: 0.9rem !important;
  font-weight: 500;
  font-family: gilroy !important;
  padding-top: 3px !important;
}

.box3-es {
  width: 100%;
  height: 120px;
  border-radius: 10px;
  border: 1px solid #d0d0d0;
  background-color: hsla(0,0%,100%,.36);
}

.box3-es > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 4px 3%;
  align-items: flex-start;
}

.textarea-es {
  height: 90px;
  padding: unset !important;
  vertical-align: unset;
  margin: unset !important;
  box-shadow: none !important;
  background: none !important;
  margin-top: 4px !important;
  cursor: auto;
  color: #000000 !important;
  font-size: 0.8rem !important;
}

.bottom-button-es {
  position: absolute;
  bottom: 35px;
  right: 35px;
}

.whiteButton-es {
  width: 150px;
  height: 48px;
  text-align: center;
  text-decoration: none;
  box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
  border-radius: 10px;
  border: 2px solid #6a82f5;
  background-color: #ffffff;
  color: #6a82f5;
  outline: none;
  margin-right: 1rem;
  transition: transform 0.5s;
}

.whiteButton-es:hover {
  transform: scale(1.1);
  color: #ffffff;
  font-weight: 600;
  background-color: #6a82f5;
}

.purpleButton-es {
  width: 150px;
  height: 48px;
  text-align: center;
  text-decoration: none;
  box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
  cursor: pointer;
  font-weight: 600;font-size: 15px;
  border-radius: 10px;
  border: 2px solid #6a82f5;
  background-color: #ffffff;
  color: #6a82f5;
  outline: none;
  transition: transform 0.5s;
}

.purpleButton-es:hover {
  transform: scale(1.1);
  color: #ffffff;
  font-weight: 600;
  background-color: #6a82f5;
}

.disable-input-es {
  background-color: #d3d3d3!important;
}