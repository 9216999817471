.modalComponent-acc {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    overflow: hidden;
    width: -webkit-fill-available !important;
    height: 100% !important;
  }
  .modalBackground-asc {
    position: fixed;
    z-index: 10;
    top: 2.5rem;
    right: 0;
    left: 0;
    bottom: 0;
    width: 46% !important;
    max-height: 66% !important;
    height: 54  !important;
    font-family: 'hurme1semibold';
    text-transform: none !important;
    border-radius: 10px;
    background: #ffffff;
    overflow-y: auto;
    overflow-x: hidden;
    margin: auto;
    box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
  }

  .acc-modal-header{
      padding: 14px !important;
      background-color: #d54b3d;
      color: #fff;
      font-weight: bold;
      font-size: 18px;
      width: -webkit-fill-available !important;
      display: flex;
      position: sticky;
      top: 0;
  }
  .acc-modal-header-dark { 
    padding: 14px !important;
    background-color: #797FF2;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    width: -webkit-fill-available !important;
    display: flex;
    position: sticky;
    top: 0;
  }
  .red-line{
    width: 72px !important;
    height: 2px !important;
    padding: 0px !important;
    background-color: #d54b3d;
  }
  .violet-line{
    width: 72px !important;
    height: 2px !important;
    padding: 0px !important;
    background-color: #797FF2;
  }
  .acc-modal-body{
      padding: 16px !important;
      width: -webkit-fill-available !important;
  }
  .acc-body-heading{
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    color: #1a1a1a;
  }
  .ml-auto{
      margin-left: auto;
  }
  .li-feature-item{
     text-align:left;
     padding: 4px;
     font-size: 16px;
     font-weight: 600;
     color: #666;
     border: none !important;
  }
  .spec-table-left{
    border: 1px solid #ddd;
    padding: 12px 16px;
    background-color: #fafafa;
    text-align: left;
    color: #1a1a1a;
  }
  .spec-table-right{
      border: 1px solid #ddd;
  }
  .spec-table{
      border-collapse: collapse;
      width: -webkit-fill-available !important;
      margin-top: 20px;
      font-size: 14px;
      font-weight: 500;
      border-radius: 8px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  }
  .spec-table tr:first-child td {
    border-top: none !important;
  }
  
  .spec-table tr:last-child td {
    border-bottom: none !important;
  }
  
  .spec-table tr td:first-child {
    border-left: none !important;
  }
  
  .spec-table tr td:last-child {
    border-right: none !important;
  }
  .product-features{ 
    margin-top:16px !important;
    margin-bottom:16px !important;
  }
  .acc-loader{
    position: absolute;
    border: 4px solid #999;
    border-top: 4px solid #000000;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    left: 49%;
    top: 47%;
    transform: translate(-50%, -50%);
    animation: spin 2s linear infinite;
    z-index: 110; 
    /* background: rgba(0, 0, 0, 0.4);    */
    vertical-align: -webkit-baseline-middle;
  }