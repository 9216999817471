.booking-container{
    background-color: white;
    white-space: break-spaces;
}
.bitem-container{
    display: flex;
    flex-direction: column;
}
.booking-item{
    display: flex;
    flex-direction: row;
}
.b-icon{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 30px;
}
.b-detail{
    padding: 10px;
    font-size: 13px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: .5px;
    text-align: left;
    /* color: rgba(0, 0, 0, 0.9); */
    width: 210px;
    /* height: 48px; */
    margin: 0px 106px 2px 10px;
}
/* .confirmation-box{
    background-color: #e6f4ff;
    padding: 16px 14px 16px 12px;
    box-shadow: 0px 1px 2px rgb(50 50 50 / 30%);
    border-top-left-radius: 20px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
} */
.c-icon{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5px;
}
.c-detail {
    padding: 5px;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: .5px;
    text-align: left;
    color: rgba(29, 29, 29, 0.54);
    width: 210px;
    /* height: 48px; */
    height: auto;
    /* margin: 0px 106px 2px 10px; */
    margin: 0px 20px 2px 10px;
}
.c-container{
    white-space: initial;
    padding: 5px;
}
.c-confirm{
    margin: 0 0 16px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: rgba(29, 29, 29, 0.87);
}